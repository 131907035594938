import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { openLoadedFormDialog } from "app/store/tools/formDialogSlice";
import { incrementDataRevision } from "app/store/tools/revisionSlice";

/**
 * DO NOT MOVE THIS FILE OR CHANGE PIPLEINE_BUILD CONST VALUE
 * THIS FILE IS BEING USED DURING DEPLOYMENT AND ANY LOCATION
 * CHANGE OF THAT FILE OR VALUE WOULD RESULT IN FAILED PIPELINE
 */

const PIPLEINE_BUILD = "v3.0.10968,v7839,release/prod,2e62400f88227282faf7e7bed6a68ffcadb96065";

const VersionComponent = () => {
	const dispatch = useDispatch();
	const currentVersion = PIPLEINE_BUILD?.split?.(",")?.[1] ?? "version";

	useEffect(() => {
		let inactive = false;
		console.log(`[VersionComponent] current build => ${PIPLEINE_BUILD}`);

		if (currentVersion === "version") {
			// No need to keep fetching remote UI version when working in local copy
			// Remove version of the code would have actuild build info in in PIPLEINE_BUILD
			return;
		}

		const loadRemoveVersion = () => {
			fetch("assets/_version?random=" + parseInt(Math.random() * 100000), { cache: "no-cache" })
				.then((r) => r.text())
				.then((build) => {
					if (inactive) return;

					if (!build || build?.includes("DOCTYPE") || !build?.split?.(",")?.[1]) {
						console.error("[VersionComponent] fetched invalid version");
					} else {
						const remoteVersion = build?.split?.(",")?.[1];
						if (
							currentVersion !== "version" &&
							remoteVersion?.length > 0 &&
							currentVersion?.length > 0 &&
							remoteVersion !== currentVersion
						) {
							console.log(
								`[VersionComponent] UI build has changed, need to reload UI, ` +
									`current build => ${PIPLEINE_BUILD}, remote build = ${build}`
							);
							dispatch(incrementDataRevision({ event: "frontendRevision" }));
						}
					}
				})
				.catch((e) => {
					console.error("[VersionComponent] failed to fetch version", e);
				});
		};

		loadRemoveVersion();
		let internal = setInterval(loadRemoveVersion, 5 * 60 * 1000); // 5 mins

		return () => {
			inactive = true;
			clearInterval(internal);
		};
		// eslint-disable-next-line
	}, []);

	const buildType = process.env.NODE_ENV === "production" ? "compressed" : "local";
	return (
		<span
			style={{ fontSize: "1.1rem" }}
			className="cursor-pointer absolute bottom-36 text-blue-700"
			onClick={() =>
				dispatch(
					openLoadedFormDialog({
						viewId: "RELEASE_LIST",
						mode: "VIEW",
						dataIds: {
							uibuild: currentVersion === "version" ? undefined : PIPLEINE_BUILD,
							uienv: process.env.REACT_APP_ENV?.toLowerCase() + " (" + buildType + ")",
						},
					})
				)
			}
		>
			{currentVersion}
		</span>
	);
};

export default VersionComponent;
