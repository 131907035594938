import React, { useEffect, useState, useMemo, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Typography } from "@material-ui/core";

import FuseSplashScreen from "@fuse/core/FuseSplashScreen";
import OnboardStepsNew from "../onboarding/OnboardStepsNew";
import config from "./signupStepsConfig";
import { getOnboardingData, updatedOnboardingData } from "app/services/onboardingServices";
import { signInWithToken } from "app/services/LoginService";
import { setUserData } from "app/store/auth/userSlice";

// Slices
import { setSignupSelectedPlan } from "app/store/auth/registerSlice";
import { fetchActions } from "app/store/actions/actionsUserSlice";
import { incrementDataRevision } from "app/store/tools/revisionSlice";
import { useHistory } from "react-router";
import { getFirstIncompleteStep } from "../onboarding/OnboardStepsNew/onboardUtil";
import UserMenu from "app/fuse-layouts/shared-components/UserMenu";

function SignupPage(props) {
	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();

	const signupRevision = useSelector(({ tools }) => tools.revision["signupRevision"]);
	const authRegister = useSelector(({ auth }) => auth.register);
	const loggedinUser = useSelector(({ auth }) => auth.user);

	const [error, setError] = useState(null);
	const [data, setData] = useState(null);
	const [verificationToken, setVerificationToken] = useState(null);
	const [loading, setLoading] = useState(false);
	const [step, setStep] = useState(-1);

	const onFinished = useCallback(async () => {
		if (data?.onboardingStatus !== "INCOMPLETE") {
			return;
		}

		setLoading(true);
		const carrierId = loggedinUser.carrier ?? loggedinUser.carrierId;
		await updatedOnboardingData(carrierId);
		let userData = await signInWithToken();
		if (userData) dispatch(setUserData(userData));

		dispatch(fetchActions({ carrierId: carrierId }));
		dispatch(incrementDataRevision({ event: "onboardingRevision" }));
		dispatch(incrementDataRevision({ event: "onboardingUpdateBannerRevision" }));
		dispatch(incrementDataRevision({ event: "paymentRevision" }));
		dispatch(incrementDataRevision({ event: "planChangeRevisionSoft" }));

		if (window?.ReactNativeWebView) {
			window?.ReactNativeWebView?.postMessage(
				JSON.stringify({
					type: "REFRESH_PLATFORM_PLAN",
					data: {
						tier: loggedinUser?.userTier,
						email: loggedinUser?.email,
						userId: loggedinUser?._id,
						authorization_token: loggedinUser?.authorization_token,
					},
				})
			);
		} else {
			history.push(`/setup`);
		}

		setLoading(false);
	}, [
		dispatch,
		history,
		loggedinUser?._id,
		loggedinUser?.authorization_token,
		loggedinUser.carrier,
		loggedinUser.carrierId,
		loggedinUser?.email,
		loggedinUser?.userTier,
		data?.onboardingStatus,
	]);
	const searchParams = useMemo(() => new URLSearchParams(location.search), [location?.search]);

	useEffect(() => {
		const urlPlanId = searchParams.get("planId");
		const urlPriceId = searchParams.get("priceId");

		if (urlPlanId && urlPriceId) {
			dispatch(setSignupSelectedPlan({ planId: urlPlanId, priceId: urlPriceId, urlParams: true }));
		}

		const urlVerificationToken = searchParams.get("verificationToken");
		if (urlVerificationToken) {
			setVerificationToken(urlVerificationToken);
		}
	}, []);

	useEffect(() => {
		// Load initial data or check email verification status
		const loadData = async () => {
			try {
				setLoading(true);
				let onboardingData = null;
				const carrierId = loggedinUser.carrier ?? loggedinUser.carrierId;

				// native mobile refresh after logging in
				const authToken = localStorage.getItem("tokenSmarthop");
				if (
					props?.onDone &&
					!!loggedinUser?.authorization_token &&
					!!authToken &&
					window?.ReactNativeWebView &&
					loggedinUser?.authorization_token !== authToken
				) {
					return props?.onDone?.({
						email: loggedinUser?.email,
						userId: loggedinUser?._id,
						authorization_token: loggedinUser?.authorization_token,
					});
				}

				if (loggedinUser?._id && carrierId) {
					onboardingData = await getOnboardingData(carrierId);
				}

				setData({
					urlPlanId: searchParams?.get("planId"),
					urlPriceId: searchParams?.get("priceId"),
					urlVerificationToken: searchParams?.get("verificationToken"),
					userId: loggedinUser?._id,
					tier: loggedinUser?.userTier,
					carrierId: carrierId,
					onboardingStatus: loggedinUser?.onboardingStatus,
					authRegister,
					verification_token: verificationToken || null,
					billing_plan_subscription_complete: onboardingData?.billing_plan_subscription_complete || false,
					signupSelectedPlan: authRegister?.signupSelectedPlan || null,
				});
			} catch (error) {
				setError(`Failed to load signup information (${error.message ?? "Unknown"})`);
			}
			setLoading(false);
		};

		loadData();
	}, [authRegister, loggedinUser, verificationToken, searchParams, props]);

	useEffect(() => {
		if (data) {
			const nextStep = getFirstIncompleteStep({ config, data });
			setStep(nextStep);
		}
	}, [data]);

	useEffect(() => {
		if (data?.onboardingStatus && data?.onboardingStatus !== "INCOMPLETE" && !window?.ReactNativeWebView) {
			return history.push(`/setup`);
		}
	}, [data, history]);

	if (error) {
		return <Typography className="text-13 pb-10 text-red text-center pt-80 px-20">{error}</Typography>;
	}

	if (!data || (props?.minStep && step < props?.minStep)) {
		return <FuseSplashScreen />;
	}

	return (
		<OnboardStepsNew
			extraFooterPadding={window?.ReactNativeWebView && step < 2}
			onFinished={onFinished}
			setLoading={setLoading}
			loading={loading}
			step={step}
			setStep={setStep}
			onSwitchSteps={(prevStep, newStep) => {
				console.log({ prevStep, newStep });
			}}
			config={config}
			data={data}
			props={{ signupRevision }}
		>
			{loggedinUser?._id && (
				<div className={"absolute top-0 right-0 z-50 hidden md:block md:mr-16"}>
					<div className="p-8">
						<UserMenu hideProfileLink={true} />
					</div>
				</div>
			)}
		</OnboardStepsNew>
	);
}

export default SignupPage;
