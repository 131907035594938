import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Typography } from "@material-ui/core";
import FuseSplashScreen from "@fuse/core/FuseSplashScreen";
import OnboardStepsNew from "../onboarding/OnboardStepsNew";
import config from "./setupStepsConfig";
import { getFirstIncompleteStep } from "../onboarding/OnboardStepsNew/onboardUtil";

// Services
import { getCarrierId, signInWithToken } from "app/services/LoginService";
import { getOnboardingData } from "app/services/onboardingServices";

//Redux
import { fetchActions } from "app/store/actions/actionsUserSlice";
import { incrementDataRevision } from "app/store/tools/revisionSlice";
import UserMenu from "app/fuse-layouts/shared-components/UserMenu";
import { getAxleDrivers, getAxleTrucks } from "app/services/carrierServices";
import { useMemo } from "react";
import { setUserData } from "app/store/auth/userSlice";
import MobileDetect from "mobile-detect";
import { useRef } from "react";
const mobileDetect = new MobileDetect(window.navigator.userAgent);

function SetupPage() {
	const dispatch = useDispatch();
	const history = useHistory();

	const carrierId = getCarrierId();

	const setupRevision = useSelector(({ tools }) => tools.revision["setupRevision"]);
	const setupDataRevision = useSelector(({ tools }) => tools.revision["setupDataRevision"]);
	const authRegister = useSelector(({ auth }) => auth.register);
	const loggedinUser = useSelector(({ auth }) => auth.user);
	const [error, setError] = useState(null);
	const [data, setData] = useState(null);
	const [otherData, setOtherData] = useState(null);
	const [step, setStep] = useState(-1);
	const [subLoading, setSubLoading] = useState(false);
	const [otherLoading, setOtherLoading] = useState(false);
	const [processing, setProcessing] = useState(false);
	const [loading, setLoading] = useState(false);
	const processingTimer = useRef(null);

	const handleMessageFromNative = useCallback((msg) => {
		try {
			const dataParsed = JSON.parse(msg.data);
			switch (dataParsed.type) {
				case "REFRESH":
					setProcessing(true);
					dispatch(incrementDataRevision({ event: "setupRevision" }));
					return;
				default:
					break;
			}
		} catch (e) {}
	}, []);

	useEffect(() => {
		const listener = mobileDetect.is("iPhone") ? window : document;
		listener.addEventListener("message", handleMessageFromNative);

		return () => {
			listener.removeEventListener("message", handleMessageFromNative);
		};
	}, [handleMessageFromNative]);

	const loadingToUse = useMemo(() => loading || subLoading, [loading, subLoading]);

	const dataToUse = useMemo(() => {
		const d = { ...data, ...otherData };
		// if (d.axleDrivers?.items) {
		// 	d.axleDrivers.items = [
		// 		...d.axleDrivers.items?.map?.((record) => {
		// 			if (authRegister?.pendingTableUpdates[record.id]) {
		// 				record = { ...record, ...authRegister?.pendingTableUpdates[record.id] };
		// 			}
		// 			return record;
		// 		}),
		// 	];
		// }
		return d;
	}, [data, otherData]);

	const onFinished = useCallback(async () => {
		setLoading(true);
		dispatch(fetchActions({ carrierId: carrierId }));
		dispatch(incrementDataRevision({ event: "setupRevision" }));

		if (window?.ReactNativeWebView) {
			window?.ReactNativeWebView?.postMessage(
				JSON.stringify({
					type: "REFRESH_PLATFORM_PLAN",
					data: {
						tier: loggedinUser?.userTier,
						email: loggedinUser?.email,
						userId: loggedinUser?._id,
						authorization_token: loggedinUser?.authorization_token,
					},
				})
			);
		} else {
			history.push(`/home`);
		}
		setLoading(false);
	}, [carrierId, dispatch, history, loggedinUser]);

	useEffect(() => {
		if (!data) return null;

		const loadData = async () => {
			setOtherLoading(true);
			try {
				// Get Onboarding Data
				const carrierIdAux = data?.setup_onboarding_carrier_id ?? carrierId;
				const [axleDrivers, axleTrucks] = await Promise.all([
					getAxleDrivers(carrierIdAux),
					getAxleTrucks(carrierIdAux),
				]);
				setOtherData({ axleDrivers, axleTrucks });
			} catch (error) {
				setError(`Failed to load setup information (${error.message ?? "Unknown"})`);
			}
			setOtherLoading(false);
		};
		loadData();
		// eslint-disable-next-line
	}, [data?.setup_onboarding_carrier_id, setupDataRevision, setupRevision, authRegister?.validateMCMethod]);

	useEffect(() => {
		const loadData = async () => {
			setLoading(true);
			try {
				// Get Onboarding Data
				const onboardingData = await getOnboardingData(carrierId);
				// Is Dispatcher Service, the onboardingInformationStatus ref to subaccount status
				const onboardingInformationStatus =
					onboardingData.setup_is_dispatcher_service && onboardingData.subaccount
						? onboardingData.subaccount.onboardingInformationStatus
						: onboardingData.onboardingInformationStatus;
				setData({
					...onboardingData,
					onboardingInformationStatus,
					hasDispatchService:
						authRegister?.hasDispatchService ?? (onboardingData.setup_is_dispatcher_service ? "YES" : "NO"),
					validateMCMethod: authRegister?.validateMCMethod ?? (onboardingData?.axleConnection ? "ELD" : null),
				});

				if (onboardingData.axleProcessing && !onboardingData?.axleConnection?.error) {
					setProcessing(!!onboardingData.axleProcessing);
					processingTimer.current = setTimeout(() => {
						dispatch(incrementDataRevision({ event: "setupRevision" }));
						dispatch(incrementDataRevision({ event: "setupDataRevision" }));
					}, [1000]);
				} else {
					setProcessing(false);
				}
			} catch (error) {
				setError(`Failed to load setup information (${error.message ?? "Unknown"})`);
				setProcessing(false);
			}
			setLoading(false);
		};
		loadData();

		return () => {
			if (processingTimer.current) {
				clearTimeout(processingTimer.current);
			}
		};
		// eslint-disable-next-line
	}, [setupRevision, authRegister?.validateMCMethod]);

	useEffect(() => {
		if (data) {
			const nextStep = getFirstIncompleteStep({ config, data });
			setStep(nextStep);
		}
	}, [data]);

	if (error) {
		return <Typography className="text-13 pb-10 text-red text-center pt-80 px-20">{error}</Typography>;
	}
	if (!data) {
		return <FuseSplashScreen />;
	}

	return (
		<OnboardStepsNew
			processing={processing}
			onFinished={onFinished}
			setLoading={setSubLoading}
			loading={loadingToUse}
			step={step}
			setStep={setStep}
			onSwitchSteps={(prevStep, newStep) => {
				console.log({ prevStep, newStep });
			}}
			config={config}
			data={dataToUse}
			props={{ setupRevision }}
		>
			{loggedinUser?._id && (
				<div className={"absolute top-0 right-0 z-50 hidden md:block md:mr-16"}>
					<div className="p-8">
						<UserMenu hideProfileLink={true} />
					</div>
				</div>
			)}
		</OnboardStepsNew>
	);
}

export default SetupPage;
