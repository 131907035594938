import _ from "@lodash";
import React, { useRef, useState } from "react";
import {
	TextField,
	Icon,
	Checkbox,
	InputAdornment,
	Grid,
	Typography,
	Chip,
	makeStyles,
	Button,
	withStyles,
	IconButton,
	Dialog,
	DialogActions,
} from "@material-ui/core";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Autocomplete from "@material-ui/lab/Autocomplete";
import parse from "autosuggest-highlight/parse";
import { useEffect } from "react";
import { openFormDialog } from "app/store/tools/formDialogSlice";
import { toShortStateViews, toBlackListCities } from "app/main/utils/stateUtils";
import { useDispatch, useSelector } from "react-redux";
import FuseUtils from "@fuse/utils";
import { useDebouncedEffect } from "@smarthop/hooks/useDebouncedEffect";
import { createTooltip } from "app/main/utils/tableUtils";

import { global } from "app/services/requestUtil";
import axios from "axios";
import { NARROW_FIELDS } from "@fuse/default-settings";
import SmarthopManualLocationSelect from "./SmarthopManualLocationSelect";
import ResourceView from "app/main/tools/ResourceView";
import { getResourceByKey } from "app/main/resource/resourceUtils";
import NewLocationSelect from "@smarthop/list/views/NewLocationSelect";
import SmarthopTextField from "@smarthop/form/fields/SmarthopTextField";

const useStyles = makeStyles((theme) => {
	return {
		endAdornment: {
			position: "absolute",
			right: "41px !important",
		},
		inputRoot: {
			paddingRight: "85px !important",
		},
		infoTooltip: {
			right: "14px",
		},
		dialogPaper: {
			position: "fixed",
			width: "95%",
			height: "90vh",
		},
		emptyRowPlaceholder: {
			"&::placeholder": {
				color: "red",
				opacity: 1,
			},
		},
	};
});

const StyledTextField = withStyles({
	root: {
		"& fieldset": {
			borderTopRightRadius: 0,
			borderBottomRightRadius: 0,
		},
	},
})(TextField);

const ENABLE_DIALOG_FOR_MOBILE = true;

const OPTION_STATUS_STUB = "_OPTION_STATUS_STUB";
const OPTION_SELECTED_STUB = "_OPTION_SELECTED_STUB";
const OPTION_ADD_LOCATION = "_OPTION_ADD_LOCATION";
const OPTION_DEFAULT_HEADER = "__OPTION_DEFAULT_HEADER__";

const badges = {
	default: "bg-grey text-white",
	info: "bg-blue text-white",
	warning: "bg-orange text-white",
	error: "bg-red text-white",
	success: "bg-green text-white",
};
const badgesDescription = {
	default: "text-grey",
	info: "text-blue",
	warning: "text-orange",
	error: "text-red",
	success: "text-green",
};

export default function SmarthopAutocompleteField(props) {
	const classes = useStyles();
	const currentOptions = props.optionsValue;
	const autocompleteConfig = props.autocomplete;
	const onChange = props.onChange;
	const onSelected = props.onSelected;
	const onBlur = props.onBlur;
	const dataIds = props.dataIds;
	const referenceParam = props.referenceParam;
	const referenceValue = props.referenceValue;

	const multiple = autocompleteConfig?.multiple;
	const showDescription = autocompleteConfig?.showDescription;
	const preloadDefault = autocompleteConfig?.preloadDefault;
	const preloadImmedately = autocompleteConfig?.preloadImmedately;
	const actionOptions = autocompleteConfig?.actionOptions?.filter((option) => !option.disabled);
	const actionOptionsValues = actionOptions?.filter((option) => !option.selectable).map((option) => option.value);
	const noFilter = autocompleteConfig.noFilter;

	const inputRef = useRef();
	const tokenRef = useRef();
	const viewId = FuseUtils.generateGUID();

	useEffect(() => {
		// Very dirty workaround to disable autocomplete in Chrome :D
		// tl;dr; Somehow autoComplete attribute value for material-ui/core/TextField component is being set to "off"
		// by material ui material-ui/core/Autocomplete component all the time, so we can't override it during the
		// render callback call, and the funny thing that "off" is being ignorred by Chrome, so the only viable options
		// I could find is to to edit the DOM manually after the initial rendor
		let input = document.getElementById(viewId)?.getElementsByTagName("input")[0];
		input?.setAttribute("autocomplete", "new-password-" + FuseUtils.generateGUID());
		input?.setAttribute("name", "name-" + FuseUtils.generateGUID());
		input?.setAttribute("data-lpignore", "true");
		// eslint-disable-next-line
	}, []);

	const dispatch = useDispatch();
	const dataRevision = useSelector(({ tools }) => tools.revision[autocompleteConfig?.listenEvent ?? "none"]);

	const [open, setOpen] = useState(false);
	const [inputValue, setInputValue] = useState(props.inputValue);
	const [inputValueMultiselect, setInputValueMultiselect] = useState(inputValue);
	const [searchValue, setSearchValue] = useState("");
	const [defaultOptions, setDefaultOptions] = useState([]);
	const [fetchedOptions, setFetchedOptions] = useState(null);
	const [ignoreOpen, setIgnoreOpen] = useState("");
	const [optionsError, setOptionsError] = useState("");
	const [loadingDefault, setLoadingDefault] = useState(false);
	const [activated, setActivated] = useState(!!preloadImmedately);
	const [searchIsActionOption, setSearchIsActionOption] = useState(false);
	const [newValue, setNewValue] = useState(null);
	const [mapOpen, setMapOpen] = useState(false);
	const [newLocationOpen, setNewLocationOpen] = useState(false);
	const [manualSelectedLocation, setManualSelectedLocation] = useState("");
	const [isMobile, setIsMobile] = useState(window.innerWidth < 960);
	const [mobileOpen, setMobileOpen] = useState(open);

	useEffect(() => {
		if (isMobile && !mobileOpen) setOpen(false);
	}, [mobileOpen, isMobile]);

	useEffect(() => {
		function handleResize() {
			if (window.innerWidth < 960 && !isMobile) {
				setIsMobile(true);
			} else if (window.innerWidth > 960 && isMobile) {
				setIsMobile(false);
			}
		}
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	});

	useEffect(() => {
		// Allowing component to maintain current state unless overriden
		if (!_.isEqual(props.inputValue, inputValue)) {
			setInputValue(props.inputValue);
		}
		// eslint-disable-next-line
	}, [props.inputValue]);

	const getMapOptions = () => [
		...(autocompleteConfig?.provider === "trimblemaps" &&
		!autocompleteConfig?.disableManualSelect &&
		!autocompleteConfig.params.types
			? [
					{
						value: "_OPTION_ADD_LOCATION",
						label: "Add new location",
						icon: "add",
						onClick: () => {
							setManualSelectedLocation(inputValue);
							setNewLocationOpen(true);
						},
					},
			  ]
			: []),
	];

	const getManualOptionsFiltered = (search) => {
		const manualOptionsStr = localStorage.getItem("manualLocations");
		const manualOptions = manualOptionsStr ? JSON.parse(manualOptionsStr) : [];
		const filteredOptions = manualOptions.filter(
			(option) =>
				typeof option?.metadata === "object" &&
				Object.values(option.metadata).some(
					(meta) =>
						search &&
						(search?.toLowerCase().includes(meta.toLowerCase()) || meta?.toLowerCase().includes(search.toLowerCase()))
				)
		);
		return filteredOptions;
	};

	// checking if value changed from original to highlight changed field

	let orignalChanged = false;
	if (!_.isNil(props.orignialValue)) {
		let orignialValues = multiple ? props.orignialValue : !_.isEmpty(props.orignialValue) ? [props.orignialValue] : [];
		if (orignialValues.length !== currentOptions.length) {
			orignalChanged = true;
		} else {
			var diff = _.differenceWith(orignialValues, currentOptions, (o1, o2) => {
				return o1.value === o2.value;
			});
			orignalChanged = diff.length > 0;
		}
	}

	// check reference values

	let requestParams = autocompleteConfig.params ?? {};
	let missingRefValue = false;

	if (!_.isEmpty(referenceParam) && referenceParam.paramKey) {
		if (!referenceValue || (typeof referenceValue === "object" && _.isEmpty(referenceValue))) {
			missingRefValue = true;
		} else {
			requestParams[referenceParam.paramKey] = referenceValue;
		}
	}

	// checking all params for fetch query

	let queryMissingParams = "";

	if (autocompleteConfig.url) {
		autocompleteConfig.url.split("/").forEach((section) => {
			if (section.startsWith(":") && (!dataIds || !dataIds[section.substring(1)])) {
				if (queryMissingParams) queryMissingParams += ",";
				queryMissingParams += section.substring(1);
			}
		});
	}

	const autocompleteService = React.useMemo(() => {
		if (autocompleteConfig?.provider === "google" && window.google?.maps?.places) {
			// To search for cities only set types to ["(cities)"] in the config file
			return new window.google.maps.places.AutocompleteService();
		} else if (autocompleteConfig?.provider === "smarthop" || autocompleteConfig?.provider === "trimblemaps") {
			return axios;
		} else {
			return null;
		}
	}, [autocompleteConfig]);

	const disabled = props.disabled || missingRefValue;

	// Fetch function
	const fetch = (request, callback) => {
		const configuration = request?.configuration ?? autocompleteConfig;
		delete request?.configuration;
		if (configuration?.provider === "google") {
			if (request.componentRestrictions?.includeOnly) delete request.componentRestrictions?.includeOnly;
			// google requires input instead of search
			request.input = request.search;

			autocompleteService.getPlacePredictions(request, (results) => {
				results?.forEach((item) => {
					let description = item.description;
					let structuredFormatting = item.structured_formatting?.secondary_text;

					if (configuration?.formatST && item.terms[1].value.length !== 2) {
						let getShortState = toShortStateViews(item.terms[1].value);
						if (getShortState) {
							description = description.replace(item.terms[1].value, getShortState);
							structuredFormatting = structuredFormatting.replace(item.terms[1].value, getShortState);
						}
					}

					item.value = description;
					item.label = description;
					item.icon = "place";
					item.description = structuredFormatting;
					item.metadata = { placeId: item.place_id };
				});
				results = results?.filter((item) => !toBlackListCities.includes(item.label.trim()));
				callback(results);
			});
		} else if (configuration?.provider === "smarthop") {
			let headers = {
				"Content-Type": "application/json",
				Authorization: "Bearer " + localStorage.getItem("tokenSmarthop"),
			};

			let url = configuration.url;
			let params = { ...(request ?? {}) };
			if (dataIds) {
				Object.keys(dataIds).forEach((key) => {
					url = url.replace(":" + key, dataIds[key]);
				});

				Object.keys(params).forEach((key) => {
					let keyValue = params[key];
					let newValue = keyValue?.includes?.(":") ? dataIds[keyValue.replace(":", "")] : null;
					if (newValue) {
						params[key] = newValue;
					}
				});
			}

			tokenRef.current?.cancel?.();
			tokenRef.current = axios.CancelToken.source();
			autocompleteService
				.create({ baseURL: global.SERVER_NAME, headers: headers, cancelToken: tokenRef.current.token })
				.get(url, { params: params, headers: headers })
				.then((res) => {
					console.log(
						`[SmarthopAutocompleteField] GET: url=${url}, params=${JSON.stringify(request)} size=${
							res.data?.options?.length
						}`
					);
					if (configuration.postFetch) {
						res.data.options = configuration.postFetch(res.data.options);
					}
					setOptionsError("");
					callback(res.data.options);
				})
				.catch((res) => {
					console.error(`[SmarthopAutocompleteField] Error, status=${res?.status}, url=${url}`);
					let response = res?.response;
					let error = response?.data?.errors?.map
						? response?.data?.errors[0]?.message
						: "Failed to load options, please try again later...";
					setOptionsError(error);
				});
		} else if (configuration?.provider === "trimblemaps") {
			let headers = {
				"Content-Type": "application/json",
				Authorization: "Bearer " + localStorage.getItem("tokenSmarthop"),
			};

			let url = configuration?.public ? "api/public/url/trimble_maps/locations" : "api/trimble_maps/locations";
			let params = { ...(request ?? {}) };
			if (dataIds) {
				Object.keys(dataIds).forEach((key) => {
					url = url.replace(":" + key, dataIds[key]);
				});

				Object.keys(params).forEach((key) => {
					let keyValue = params[key];
					let newValue = keyValue?.includes?.(":") ? dataIds[keyValue.replace(":", "")] : null;
					if (newValue) {
						params[key] = newValue;
					}
				});
			}

			tokenRef.current?.cancel?.();
			tokenRef.current = axios.CancelToken.source();
			autocompleteService
				.create({ baseURL: global.SERVER_NAME, headers: headers })
				.get(url, { params: params, headers: headers, cancelToken: tokenRef.current.token })
				.then((res) => {
					console.log(
						`[trimbleMapsAutocompleteField] GET: url=${url}, params=${JSON.stringify(request)} size=${
							res.data?.options?.length
						}`
					);
					setOptionsError("");
					res.data?.options?.forEach((item) => {
						let structuredFormatting = item.structured_formatting?.secondary_text;
						item.icon = "place";
						item.description = structuredFormatting;
					});
					res.data.options = res.data?.options?.filter((item) => !toBlackListCities.includes(item.label.trim()));
					callback(res.data.options);
				})
				.catch((res) => {
					console.error(`[trimbleMapsAutocompleteField] Error, status=${res?.status}, url=${url}`, res);
					let response = res?.response;
					let error = response?.data?.errors?.map
						? response?.data?.errors[0]?.message
						: "Failed to load options, please try again later...";
					setOptionsError(error);
				});
		}
	};

	const groupOptions = (optionGroups) => {
		let to = [];
		optionGroups?.forEach((from) => {
			from?.forEach((fromItem) => {
				let found = to.find((toItem) => toItem?.value === fromItem?.value);
				if (!found) to.push(fromItem);
			});
		});

		if (multiple) {
			to = to.sort((a, b) => {
				if (a.value === "_OPTION_STATUS_STUB") return -1;
				if (b.value === "_OPTION_STATUS_STUB") return 1;
				return a?.label?.localeCompare(b.label);
			});
		}

		return to;
	};

	useEffect(() => {
		if (!activated || !preloadDefault || queryMissingParams || missingRefValue) {
			return;
		}
		let active = true;
		if (!autocompleteService) {
			return;
		}

		try {
			let paramsToUse = autocompleteConfig?.defaultOptions?.params ?? requestParams;
			Object.keys(paramsToUse).forEach((k) => {
				if (paramsToUse[k]?.includes?.("__FROM_VALUE_REF__")) {
					const valueToRef = paramsToUse[k].replace("__FROM_VALUE_REF__", "");
					paramsToUse[k] = props?.formData?.[valueToRef];
				}
			});

			if (autocompleteConfig?.defaultOptions) {
				paramsToUse.configuration = autocompleteConfig?.defaultOptions;
			}

			setLoadingDefault(true);
			fetch({ search: "", ...paramsToUse }, (defaultOption) => {
				if (active) {
					setDefaultOptions(defaultOption);
					setLoadingDefault(false);
				}
			});
		} catch (err) {
			console.error(`[Fetch] failed to fetch data`, err);
		}
		return () => {
			active = false;
		};
		// eslint-disable-next-line
	}, [dataRevision, referenceValue, activated]);

	useDebouncedEffect(
		() => {
			if (!newValue) return;
			if (onBlur) onBlur(props.name);
			// eslint-disable-next-line
		},
		[newValue],
		500
	);

	useDebouncedEffect(
		() => {
			let active = true;
			if (!autocompleteService || queryMissingParams) {
				return;
			}
			if (searchValue === "") {
				return;
			}

			if (searchIsActionOption) {
				setSearchIsActionOption(false);
				return;
			}

			let fetchParams = autocompleteConfig.params ?? {};
			if (!_.isEmpty(referenceParam) && referenceParam.paramKey) {
				if (!referenceValue) {
					console.error(`[SmarthopAutocompleteField] Missing reference value`);
					return;
				} else {
					fetchParams[referenceParam.paramKey] = referenceValue;
				}
			}

			fetch({ search: searchValue, ...requestParams }, (fetchedOption) => {
				if (inputRef.current.value !== searchValue) {
					console.warn("[Outdated] recevied outdated results", inputRef.current.value, searchValue);
					// Return results for outdated input
					return;
				}

				const manualOptions = getManualOptionsFiltered(searchValue);
				if (active) {
					setSearchValue("");
					setIgnoreOpen(false);
					if (searchValue !== "") {
						setFetchedOptions([...fetchedOption, ...manualOptions]);
					}
				}
			});

			return () => {
				active = false;
			};
		},
		// eslint-disable-next-line
		[searchValue],
		props?.field?.commitDelay ?? 1000
	);

	const disableClearable = React.useMemo(() => {
		if (!props?.autocomplete?.disableClearable) return !props.inputValue;

		if (typeof props?.autocomplete?.disableClearable === "function") {
			return props.autocomplete.disableClearable(props.inputValue);
		}

		return !props.inputValue || !!props?.autocomplete?.disableClearable;
	}, [props.inputValue, props?.autocomplete]);

	if (!autocompleteService) {
		return (
			<Typography color="textSecondary" className="mb-10 mt-10 font-normal">
				{`Not supported autocomplete provider '${autocompleteConfig?.provider ?? "<null>"} ' for field '${
					props.label ?? "<null>"
				}'`}
			</Typography>
		);
	}

	if (queryMissingParams) {
		return (
			<Typography color="textSecondary" className="mb-10 mt-10 font-normal">
				Autocomplete fetch url '{autocompleteConfig.url}' required mandatory params [{queryMissingParams}] which were
				not provided in 'dataIds' props
			</Typography>
		);
	}

	const statusOption = loadingDefault
		? { type: "SYSTEM", icon: "cached", label: "Loading..." }
		: searchValue
		? { type: "SYSTEM", icon: "cached", label: "Searching..." }
		: optionsError
		? { type: "SYSTEM", icon: "error", label: optionsError }
		: !_.isNull(fetchedOptions)
		? {
				type: "SYSTEM",
				icon: "format_list_bulleted",
				label: "Found " + fetchedOptions.length + ` option${fetchedOptions.length === 1 ? "" : "s"}`,
		  }
		: (multiple && !!inputValue) || (!multiple && !!inputValue && inputValue !== currentOptions[0]?.label)
		? { type: "SYSTEM", icon: "format_list_bulleted", label: "No options found" }
		: { type: "SYSTEM", icon: "keyboard", label: "Type to search..." };
	statusOption.value = OPTION_STATUS_STUB;

	let additionalOptions = [];

	if (
		!multiple &&
		currentOptions[0]?.value !== undefined &&
		currentOptions[0]?.value !== null &&
		!autocompleteConfig.anyValue
	) {
		additionalOptions.push({
			value: OPTION_SELECTED_STUB,
			label: currentOptions[0].label,
			icon: "check_box",
			description: currentOptions[0].description,
			badge: currentOptions[0].badge,
			border: true,
		});
	}
	additionalOptions.push(statusOption);

	const mapOptions = getMapOptions();

	const allOptions = groupOptions([
		additionalOptions,
		currentOptions,
		[
			...(autocompleteConfig.anyValue && inputValue?.length > 0
				? [{ value: inputValue, label: inputValue, description: "Manual Input", icon: "keyboard" }]
				: []),
		],
		!_.isNull(fetchedOptions) ? fetchedOptions : defaultOptions,
		actionOptions,
		mapOptions,
	]);

	const renderInputView = (params) => {
		let label = props.label
			? props.label + (props.label && props.required ? " *" : "") + (orignalChanged ? " (Edited)" : "")
			: null;
		let errorMessageSuspended = props?.field?.noErrorMessage;
		let errorMessage = optionsError ? optionsError : props.error?.message;
		let badge = props?.autocomplete?.badge;
		let inputClasses = props.input?.classes ?? params.InputProps.classes ?? {};

		const descriptionVisible =
			showDescription && currentOptions?.[0]?.description && currentOptions?.[0]?.label === inputValue;
		if (descriptionVisible) {
			inputClasses.input = (inputClasses.input ?? "") + " -mt-8 mb-8 ";
		}

		let inputParamOverrides = {};

		if (multiple) {
			inputClasses.root = (inputClasses.root ?? "") + " flex-col-reverse d-flex content-start p-8 ";
			inputParamOverrides = {
				placeholder: "Search",
				className: "w-full p-0 py-8 px-2 my-2 text-xs",
			};

			if (currentOptions?.length > 0) {
				inputParamOverrides.style = { borderBottom: "solid thin #ccc" };
			}
		} else {
			// Show empty placeholder text red when the field style set to "row"
			if (!inputValue?.length && props.field?.variant === "row" && props.required) {
				inputParamOverrides.className = classes.emptyRowPlaceholder;
			}
		}

		const showManualLocation =
			autocompleteConfig?.provider === "trimblemaps" &&
			!autocompleteConfig?.disableManualSelect &&
			!requestParams.types;
		const resourceInfo = autocompleteConfig?.resource ? getResourceByKey(autocompleteConfig?.resource) : null;

		const TextFieldComponent = showManualLocation ? StyledTextField : TextField;

		const contenView = (
			<div
				id={viewId}
				className={
					(disabled ? "opacity-60 " : "") +
					" relative w-full  " +
					(props.field?.variant !== "row" ? " mb-4 mt-4 px-4 " : " mb-2 px-2 ") +
					(props?.field?.noErrorMessage && props.field?.variant !== "row" ? " pt-4 pb-4 " : "")
				}
			>
				<div className="flex flex-row">
					<TextFieldComponent
						{...params}
						inputRef={inputRef}
						ref={props.innerRef}
						name={props.name}
						key={props.name}
						label={props.field?.variant === "row" ? "" : label}
						error={!!errorMessage}
						classes={props.field?.variant === "filled" ? props.field?.classes : {}}
						placeholder={autocompleteConfig?.placeholder}
						helperText={!errorMessageSuspended ? errorMessage : ""}
						variant={props.field?.variant === "row" ? "standard" : props.field?.variant ?? "outlined"}
						value={inputValue}
						disabled={disabled}
						FormHelperTextProps={{ classes: { root: "mt-0 ml-1 mr-1 mb-1" } }}
						className={"w-full pr-0"}
						inputProps={{
							...params.inputProps,
							...inputParamOverrides,
						}}
						InputProps={{
							...params.InputProps,
							classes: inputClasses,
							endAdornment: (
								<>
									{!isMobile ? (
										params.InputProps.endAdornment
									) : (
										<>
											<InputAdornment position="end" className={"absolute right-10"}>
												{!disableClearable && (
													<IconButton
														className="-mr-6"
														onClick={(e) => {
															e.stopPropagation();
															onViewChange(null, null);
														}}
													>
														<Icon className="text-20 text-grey" color="action">
															clear
														</Icon>
													</IconButton>
												)}
												<Icon className="text-20" color="action">
													arrow_drop_down
												</Icon>
											</InputAdornment>
										</>
									)}
									{resourceInfo && !props.inputValue ? (
										<InputAdornment position="end">
											<ResourceView
												type={"icon"}
												description={resourceInfo?.description}
												link={resourceInfo?.link}
												classes={{ icon: " -mr-2 -mt-1 " }}
											/>
										</InputAdornment>
									) : props.warning ? (
										createTooltip(
											<InputAdornment position="end">
												<div className="flex flex-row">
													<Icon className="text-20 text-orange-900">{"warning"}</Icon>
												</div>
											</InputAdornment>,
											<Typography className="break-words whitespace-normal tracking-wide leading-4 mb-2 min-w-100 text-12">
												{props.warning}
											</Typography>,
											"bg-gray"
										)
									) : props.description ? (
										createTooltip(
											<InputAdornment position="end">
												<Icon className="text-16" color="action">
													{"info"}
												</Icon>
											</InputAdornment>,
											<Typography className="break-words whitespace-normal tracking-wide leading-4 mb-2 min-w-100 text-12">
												{props.description}
											</Typography>,
											"bg-gray"
										)
									) : null}
								</>
							),
						}}
						onChange={(event) => {
							setInputValueMultiselect(event.target.value);
							if (!event.target.value && multiple) {
								setFetchedOptions(null);
							}
						}}
					/>
					{showManualLocation && (
						<>
							{props.field?.variant !== "row" ? (
								<Button
									className="flex-1"
									style={{
										borderRadius: "0 4px 4px 0",
										border: "solid thin rgba(0, 0, 0, 0.23)",
										borderLeft: 0,
										maxHeight: "48px",
									}}
									variant="contained"
									size="small"
									onClick={() => setMapOpen((o) => !o)}
								>
									<Icon className="text-24" color="action">
										{"place"}
									</Icon>
								</Button>
							) : (
								<IconButton className=" border-1 ml-5 -mr-2 -mt-4" size="small" onClick={() => setMapOpen((o) => !o)}>
									<Icon className="text-20" color="action">
										{"place"}
									</Icon>
								</IconButton>
							)}
							<SmarthopManualLocationSelect
								open={mapOpen}
								setOpen={setMapOpen}
								onLocationSelected={onLocationSelected}
								startingLocation={currentOptions?.length > 0 ? currentOptions[0] : null}
							/>
							<NewLocationSelect
								open={newLocationOpen}
								setOpen={setNewLocationOpen}
								location={manualSelectedLocation}
								selectLocation={(location) => onViewChange(null, location)}
							/>
						</>
					)}
				</div>
				{descriptionVisible && (
					<Typography
						color="textSecondary"
						className={
							"whitespace-nowrap truncate pr-10 " +
							(props.field?.variant !== "row" ? "h-24 text-12 pl-14 -mt-24 " : "h-16 text-11 -mt-16 ")
						}
					>
						{currentOptions?.[0]?.description}
					</Typography>
				)}
				{props.field?.variant !== "row" && !errorMessageSuspended && !errorMessage && (
					<div style={{ height: NARROW_FIELDS ? "1.6rem" : "1.9rem" }} />
				)}
			</div>
		);

		if (props.field?.variant === "row") {
			return (
				<div
					className={
						`flex w-full flex-row justify-between ` +
						(props.field?.classes?.root?.includes("border-") ? "" : " border-b-1 border-grey-300 ") +
						(props.field?.classes?.root?.includes("mt-") ? "" : " mt-10 ") +
						(props.field?.classes?.root ?? "")
					}
				>
					{label && (
						<Typography
							className={
								`${"flex w-1/3 pt-2"} ` +
								(disabled ? " opacity-60 " : "") +
								(props.field?.classes?.title?.includes("text-") ? "" : " text-black text-12 ml:text-13 ") +
								(props.field?.classes?.title ?? "")
							}
						>
							{label}
						</Typography>
					)}
					{badge?.show && (
						<Button
							key={"trip_detail_button_" + badge.label}
							className={`text-xs py-1 ml-2 mr-4 mb-2 ${badge.className}`}
							variant={"contained"}
							onClick={badge.onClick}
						>
							{badge.label}
							{badge.description &&
								!badge.onClick &&
								createTooltip(<Icon className="text-13 ml-4 mt-2">info</Icon>, badge.description)}
							{badge.description && badge.onClick && <Icon className="text-13 ml-4 mt-2 -mr-4">info</Icon>}
						</Button>
					)}
					<div className={"flex flex-row " + (label ? " w-2/3 " : " w-full ")}>{contenView}</div>
				</div>
			);
		} else {
			return contenView;
		}
	};

	const renderTagsView = (tagValue, getTagProps) => {
		return (
			<div className="overflow-y-scroll max-h-96 w-full mt-3">
				{tagValue.map((option, index) => (
					<Chip size="small" {...getTagProps({ index })} label={option.label} />
				))}
			</div>
		);
	};

	const renderOptionView = (option, data) => {
		if (!option) return;

		const multiIcon = <CheckBoxOutlineBlankIcon fontSize="small" />;
		const multiCheckedIcon = <CheckBoxIcon fontSize="small" />;

		let matches;
		let parts;
		if (option?.structured_formatting && option?.structured_formatting?.main_text_matched_substrings) {
			matches = option?.structured_formatting?.main_text_matched_substrings;
			parts = parse(
				option?.structured_formatting?.main_text,
				matches?.map((match) => [match?.offset, match?.offset + match?.length])
			);
		} else {
			matches = [];
			parts = [{ highlight: false, text: getOptionViewLabel(option) }];
		}

		let lBadges = [];
		let dBadges = [];
		if (option?.badges?.length > 0) {
			lBadges = option.badges.filter((item) => !item.position || item.position === "LABEL");
			dBadges = option.badges.filter((item) => item.position === "DESCRIPTION");
		}

		return (
			<div className="w-full">
				<div
					className={
						"flex flex-row items-center truncate px-2 " +
						(isMobile ? " min-h-40 border-b-1 pb-14" : "") +
						(option?.border ? " border-b-1 pb-5 -mb-5" : "")
					}
				>
					{multiple ? (
						option.value !== "_OPTION_STATUS_STUB" ? (
							<div>
								<Checkbox
									icon={multiIcon}
									checkedIcon={multiCheckedIcon}
									style={{ marginRight: 8 }}
									checked={data.selected}
								/>
							</div>
						) : (
							""
						)
					) : (
						<div style={{ height: "3rem" }} className="pt-4">
							<Grid item>
								{option?.icon && (
									<Icon className="text-18 mr-10 mt-2" color="action">
										{option?.icon}
									</Icon>
								)}
							</Grid>
						</div>
					)}

					<Grid item xs>
						{parts.map((part, index) => (
							<span
								className={"flex flex-row space-x-4 items-center " + (option.type === "SYSTEM" ? " italic py-8 " : "")}
								key={index}
								style={{ fontWeight: part.highlight ? 700 : 400 }}
							>
								<span>{part.text}</span>
								{lBadges?.map((item) => (
									<Typography
										key={part.text + "-" + item?.type}
										component="div"
										className={`uppercase inline-block font-light text-10 px-5 py-1 -mt-2 ml-2 items-center justify-center ${
											badges?.[item?.type ?? "default"]
										}`}
									>
										{item?.icon && <Icon className="text-12 -mb-2 mr-3">{item?.icon}</Icon>}
										{item?.label}
									</Typography>
								))}
							</span>
						))}
						<Typography component="div" variant="body2" color="textSecondary" className="font-light">
							<span>{option?.description ?? ""}</span>
							{dBadges?.map((item) => (
								<Typography
									key={"dBadges" + item?.type + "-" + item.label}
									component="div"
									className={`uppercase font-medium inline-block text-12 ml-5 items-center justify-center ${
										badgesDescription?.[item.type ?? "default"]
									}`}
								>
									{item?.icon && <Icon className="text-13 -mb-2 mr-2">{item?.icon}</Icon>}
									{item.label}
								</Typography>
							))}
						</Typography>
					</Grid>
				</div>
			</div>
		);
	};

	const getOptionViewLabel = (option) =>
		!option?.label ? (!option?.value ? "" : option?.value) : typeof option === "string" ? option : option.label;

	const onViewChange = (event, newValue) => {
		if (!newValue) {
			newValue = [];
		}
		let single = !Array.isArray(newValue);
		if (single) {
			newValue = [newValue];
		}
		// If action is selected, removing it from set of current options
		let selectedAction = newValue.find(
			(option) =>
				actionOptionsValues?.includes(option?.value) ||
				option?.value === OPTION_SELECTED_STUB ||
				option?.value === OPTION_STATUS_STUB ||
				option?.value === OPTION_ADD_LOCATION ||
				option?.value === OPTION_DEFAULT_HEADER
		);
		let newOptionsValues = newValue.map((item) => ({
			value: item?.value,
			label: item?.label,
			description: item?.description,
			metadata: item?.metadata,
		}));
		newOptionsValues = selectedAction ? currentOptions : newOptionsValues;

		if (selectedAction?.dialog?.formId) {
			dispatch(
				openFormDialog({
					formId: selectedAction?.dialog?.formId,
					mode: selectedAction?.dialog?.mode,
					dataIds: { ...dataIds, ...selectedAction.dialog.dataIds },
				})
			);
		}

		if (selectedAction?.dialog?.viewId) {
			dispatch(
				openFormDialog({
					viewId: selectedAction?.dialog?.viewId,
					mode: selectedAction?.dialog?.mode,
					dataIds: { ...dataIds, ...selectedAction.dialog.dataIds },
				})
			);
		}

		if (selectedAction?.onClick) {
			selectedAction?.onClick();
		}

		// If actions select in single mode, reset to initial value, in multiple mode set value to ""
		let newInputValue = selectedAction
			? !multiple
				? inputValue
				: ""
			: !multiple && newOptionsValues[0]
			? newOptionsValues[0].label
			: "";

		if (selectedAction) {
			setSearchIsActionOption(true);
			return;
		}

		if (onChange) onChange(newOptionsValues, newInputValue);
		if (onSelected) onSelected(newOptionsValues);
		if (newOptionsValues.length > 0) setNewValue(newOptionsValues);

		if (autocompleteConfig?.onValueChange) autocompleteConfig.onValueChange(newOptionsValues);
		if (!multiple && newValue.length > 0) {
			// Closing menu after
			inputRef.current?.blur();
		}
	};

	const onLocationSelected = (newValues) => {
		onViewChange(null, newValues);
		setMapOpen(false);
	};

	const onViewInputChange = (event, newInputValue) => {
		// if menu is not opened, ignoring it until earch results to avoid annoing
		// flushing of the menu
		if (!open && newInputValue) {
			setIgnoreOpen(true);
		}

		setInputValue(newInputValue);
		if (!multiple) {
			if (currentOptions[0]?.label === newInputValue || !newInputValue?.length) {
				setSearchValue("");
				setIgnoreOpen(false);
				setFetchedOptions(null);
			} else {
				setSearchValue(newInputValue);
				if (open && _.isNull(fetchedOptions)) {
					// Showing default values while running first seach when list of options is opened
					setFetchedOptions(defaultOptions);
				}
			}
		} else {
			setSearchValue(newInputValue);
			if (!newInputValue?.length) {
				setFetchedOptions(null);
			} else if (open && _.isNull(fetchedOptions)) {
				// Showing default values while running first seach when list of options is opened
				setFetchedOptions(defaultOptions);
			}
		}
	};

	const getViewOptionSelected = (option, value) => {
		return option?.value === value?.value;
	};

	const getViewOptionDisabled = (option) => {
		if (props.autocomplete?.maxValues === currentOptions?.length) {
			return true;
		}
		return option?.disabled || option?.value === OPTION_STATUS_STUB || option?.value === OPTION_DEFAULT_HEADER;
	};

	const onBlurField = (e) => {
		if (currentOptions?.length <= props.autocomplete?.maxValues) setOptionsError("");
		setSearchValue("");
		setIgnoreOpen(false);
		setOpen(false);
		setFetchedOptions(null);
		onBlur?.();
	};

	let autocomplete = null;
	if (multiple) {
		autocomplete = (
			<Autocomplete
				name={props.name}
				key={props.key}
				multiple={true}
				defaultValue={[]}
				value={currentOptions}
				inputValue={inputValueMultiselect}
				className={"w-full pr-0 pl-0"}
				classes={props.autocomplete?.classes ?? {}}
				options={allOptions}
				noOptionsText={statusOption.label}
				includeInputInList
				onChange={(event, newValue) => onViewChange(event, newValue)}
				onInputChange={(event, newInputValue) => onViewInputChange(event, newInputValue)}
				filterOptions={(options, state) => {
					if (!inputValueMultiselect || noFilter) return options;
					const res = options.filter((option) => {
						return (
							option?.value?.toLowerCase?.()?.includes(inputValueMultiselect.toLowerCase()) ||
							option?.label?.toLowerCase?.()?.includes(inputValueMultiselect.toLowerCase()) ||
							option?.description?.toLowerCase?.()?.includes(inputValueMultiselect.toLowerCase())
						);
					});
					return res;
				}}
				open={open && !ignoreOpen}
				onOpen={() => {
					setOpen(true);
					if (!activated) {
						setActivated(true);
					}
				}}
				onClose={() => setOpen(false)}
				getOptionLabel={getOptionViewLabel}
				getOptionSelected={getViewOptionSelected}
				getOptionDisabled={getViewOptionDisabled}
				renderInput={renderInputView}
				renderOption={renderOptionView}
				renderTags={renderTagsView}
				onBlur={onBlurField}
				filterSelectedOptions={false}
				disableCloseOnSelect={true}
				disabled={disabled}
			/>
		);
	} else {
		autocomplete = (
			<Autocomplete
				name={props.name}
				key={props.key}
				multiple={false}
				defaultValue={""}
				disabled={disabled}
				value={currentOptions[0] ?? null}
				inputValue={inputValue}
				className={"w-full pr-0"}
				classes={
					props.autocomplete?.classes ??
					(props.description ? { endAdornment: classes.endAdornment, inputRoot: classes.inputRoot } : {})
				}
				options={allOptions}
				noOptionsText={statusOption.label}
				includeInputInList
				filterSelectedOptions
				open={open && !ignoreOpen}
				onOpen={() => {
					if (isMobile && ENABLE_DIALOG_FOR_MOBILE) {
						setMobileOpen(true);
					} else {
						setOpen(true);
					}
					if (!activated) {
						setActivated(true);
					}
				}}
				onClose={() => setOpen(false)}
				onChange={(event, newValue) => onViewChange(event, newValue)}
				onInputChange={(event, newInputValue) => onViewInputChange(event, newInputValue)}
				filterOptions={(x) => x}
				getOptionLabel={getOptionViewLabel}
				getOptionSelected={getViewOptionSelected}
				getOptionDisabled={getViewOptionDisabled}
				renderInput={renderInputView}
				renderOption={renderOptionView}
				onBlur={onBlurField}
				disableClearable={disableClearable}
			/>
		);
	}

	return (
		<>
			{autocomplete}
			{mobileOpen && (
				<Dialog
					fullScreen={false}
					open={true}
					PaperProps={{ square: true, variant: "outlined" }}
					classes={{ paper: classes.dialogPaper }}
				>
					<div className="flex flex-row items-center mt-6 -mb-4">
						<Button onClick={() => setMobileOpen(false)}>
							<Icon>arrow_back</Icon>
						</Button>
						<Typography className="flex-1 text-15 font-semibold">{props.label}</Typography>
						<DialogActions>
							<Button className="text-15 text-grey" onClick={() => setMobileOpen(false)}>
								Close
							</Button>
						</DialogActions>
					</div>
					<div className="flex flex-col w-full px-8">
						<SmarthopTextField
							inputRef={inputRef}
							field={{ variant: "outlined" }}
							showClearIcon
							onChangeCommitted={(key, value) => {
								onViewInputChange(null, value);
							}}
						/>
						{allOptions.map((item, index) => {
							if (item.value === currentOptions[0]?.value) return null;
							return (
								<div
									key={item.value + "_" + index}
									className="pb-12 px-8"
									onClick={() => {
										onViewChange(null, item);
										setMobileOpen(false);
									}}
								>
									{renderOptionView(item)}
								</div>
							);
						})}
					</div>
				</Dialog>
			)}
		</>
	);
}
