import { Icon, Typography } from "@material-ui/core";
import { isStepCompleted, isStepAllowed, isStepVisible } from "./onboardUtil";

function OnboardSidebar({ step, config, data, switchToStep, isMobile }) {
	const renderItem = (sectionStep) => {
		const stepCompleted = isStepCompleted({ step: sectionStep, config, data });
		const isAllowed = isStepAllowed({ step: sectionStep, config, data });
		const isVisible = isStepVisible({ step: sectionStep, config, data });

		if (!isVisible) return null;

		const sectionClass =
			"rounded-md py-20 w-full ml:w-11/12 " +
			(step === sectionStep
				? config.itemClass
					? config.itemClass
					: "bg-orange-50"
				: !isAllowed || data?.onboardingStatus === "REJECTED"
				? "opacity-50 cursor-not-allowed"
				: "cursor-pointer");
		const iconClass = "text-28 ml-14 mr-4 " + (stepCompleted ? " text-green-400 " : " text-grey-800 ");
		const titleClass = "text-15 ml:text-16 mb-1 " + (stepCompleted ? " text-grey-600 " : " text-grey-900 ");
		const subtitleClass = "text-13 -mb-10 -mt-4 " + (stepCompleted ? " text-green-400 " : " text-grey-600 ");
		const navIconClass = "text-14 " + (stepCompleted ? " text-grey-600 " : " text-grey-900 ");
		return (
			<div key={"step_" + sectionStep} className="flex my-2 ml:my-2 w-full items-center justify-center">
				<div
					className={sectionClass}
					onClick={() => data?.onboardingStatus !== "REJECTED" && isAllowed && switchToStep(sectionStep)}
				>
					<div className="flex flex-row items-center ">
						<Icon className={iconClass}>{stepCompleted ? "check_circle" : "radio_button_unchecked"}</Icon>
						<div className="flex flex-1 flex-col items-start -mt-8	pb-4 pr-10 pl-10">
							<Typography className={titleClass}>{config.list[sectionStep].title}</Typography>
							<Typography className={subtitleClass}>{config.list[sectionStep].subtitle}</Typography>
						</div>
						{isMobile && <Icon className={navIconClass}>arrow_forward_ios</Icon>}
					</div>
				</div>
			</div>
		);
	};

	return (
		<div className="flex flex-col items-center">
			<div className="flex flex-col justify-start items-start px-16 ml:px-40 pb-12">
				<Typography className="flex flex-1 text-17 mb-4">{config.sidebarHeader}</Typography>
				{config.sidebarDescription && (
					<Typography color="textSecondary" className="text-13">
						{config.sidebarDescription}
					</Typography>
				)}
				{config.renderSidebarDescription?.({ config, data })}
			</div>
			{config.list.map((s, index) => renderItem(index))}
			<div className="flex w-32 min-h-28" />
		</div>
	);
}

export default OnboardSidebar;
