export const isStepCompleted = ({ step, config, data }) => {
	if (step === -1) return true;
	return config.list?.[step]?.isComplete?.(data);
};

export const isAllStepsCompleted = ({ config, data, props }) => {
	const filter = config.list.filter((list) => {
		if (list.hidden && list.hidden(data, props)) {
			return false;
		}
		return true;
	});
	return filter.reduce((acc, s) => acc && s.isComplete(data), true);
};

export const hasPrevStep = ({ step, config }) => {
	return config.list[step - 1] !== undefined;
};

export const hasNextStep = ({ step, config }) => {
	return config.list[step + 1] !== undefined;
};

export const isComplete = ({ config, data, props }) => {
	return isAllStepsCompleted({ config, data, props }) && config?.isComplete?.(data);
};

export const getFirstIncompleteStep = ({ config, data }) => {
	const filterConfig = config.list.filter((item) => !item.hidden || !item.hidden(data));

	let i;
	for (i = 0; i < filterConfig.length; i++) {
		if (!filterConfig[i].isComplete(data)) {
			return i;
		}
	}
	return i - 1;
};

export const isStepAllowed = ({ step, config, data }) => {
	if (step === -1) return true;
	return !config.list[step]?.isAllowed ? true : config.list[step]?.isAllowed?.(data) ?? false;
};

export const isNextStepsAllowed = ({ step, config, data }) => {
	// Iterate over all steps and check if all are allowed and at least one is not completed
	for (let i = step; i < config.list.length; i++) {
		if (!isStepAllowed({ step: i, config, data })) {
			return false;
		}
	}
	return true;
};

export const isStepVisible = ({ step, config, data, props }) => {
	const stepItem = config.list[step];
	if (stepItem && stepItem.hidden && stepItem.hidden(data, props)) {
		return false;
	}
	return true;
};
