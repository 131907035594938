import Typography from "@material-ui/core/Typography";
import { formatCurrency } from "app/main/utils/tableUtils";

const feeFormatValue = (stripeData) => {
	const interval = stripeData?.recurring?.interval;
	const intervalCount = stripeData?.recurring?.interval_count;
	return `${interval ? "Every " + intervalCount + " " + interval + "(s)" : ""}`;
};

const priceBuilderFormat = (priceData) => {
	return (
		<div className="flex flex-col">
			<Typography className="break-words whitespace-normal tracking-wide leading-4 mb-2 min-w-100 text-12 ml:text-13">
				{priceData?.label}
			</Typography>
			<Typography className="break-words whitespace-normal tracking-wide text-grey-500 text-10 ml:text-11 -mt-3">
				{priceData?.value}
			</Typography>
		</div>
	);
};

const feeBuilderFormat = (value, priceData) => {
	return (
		<div className="flex flex-col">
			<Typography className="break-words whitespace-normal tracking-wide leading-4 mb-2 min-w-100 text-12 ml:text-13">
				{priceData?.metadata?.stripe?.tiers_mode === "graduated" ? "Graduate" : formatCurrency(value)}
			</Typography>
			<Typography className="break-words whitespace-normal tracking-wide text-grey-500 text-10 ml:text-11 -mt-3">
				{feeFormatValue(priceData?.metadata?.stripe)}
			</Typography>
		</div>
	);
};

const plans = {
	urlGET: "billing/platform-plans",
	idKey: "_id",
	listenEvent: "planRevision",
	segmentKey: "ADMIN_PLATFORM_PLANS",
	content: {
		table: {
			variant: "skinny",
			orderBy: ["nickname", "value", "created_at"],
		},
		pagination: {
			enabled: true,
			resetScroll: true,
		},
		order: {
			defaut: {
				key: "created_at",
				dir: "desc",
			},
		},
		filters: {
			search: true,
			items: [
				{
					key: "public",
					type: "select",
					label: "Plan Visibility",
					defaultValue: "__NOT_SELECTED__",
					options: [
						{
							value: "__NOT_SELECTED__",
							label: "Not Selected",
						},
						{
							value: "TYPE_PUBLIC",
							label: "Public",
						},
					],
				},
				{
					key: "plan_type",
					type: "select",
					label: "Plan Type",
					defaultValue: "__NOT_SELECTED__",
					options: [
						{
							value: "__NOT_SELECTED__",
							label: "Not Selected",
						},
						{
							value: "MAIN",
							label: "Main",
						},
						{
							value: "ADDON",
							label: "Addon",
						},
					],
				},
			],
		},
		groups: {
			items: [
				{
					key: "internal",
					type: "checkbox",
					label: "Show Internal Fields",
				},
			],
		},
		isHighlighted: (item) => !!item.public,
		items: [
			{ key: "created_at", type: "date", label: "Creation Date" },
			{ key: "_id", type: "id", label: "ID", group: "internal" },
			{ key: "nickname", type: "string", label: "Name" },
			{ key: "description", type: "string", label: "Description", collapsibleLength: 12 },
			{
				key: "plan_type",
				type: "string",
				label: "Type",
				builder: (item) => ({ MAIN: "Main", ADDON: "Addon" }[item.plan_type]),
			},
			{ key: "bulletPoints", type: "string", label: "Bullet Points", collapsibleLength: 12 },
			{ key: "tier", type: "string", label: "Tier" },
			{ key: "type", type: "text", label: "Handler" },
			{ key: "public", type: "bool", label: "Public" },
			{ key: "excludeDiscounts", type: "bool", label: ["Exclude", "Discounts"] },
			{ key: "popular", type: "bool", label: ["Most", "Popular"] },
			{
				key: "trialDays",
				type: "string",
				label: ["Inital Trial"],
				builder: (item) => (item.trialDays > 0 ? item.trialDays + " days" : "-"),
			},
			{
				key: "postTrialDays",
				type: "string",
				label: ["Post-Purchase", "Trial"],
				builder: (item) => (item.postTrialDays > 0 ? item.postTrialDays + " days" : "-"),
			},
			{
				builder: (item) => {
					return (
						<div className="flex flex-col">
							<Typography className="break-words whitespace-normal tracking-wide leading-4 mb-2 min-w-100 text-12 ml:text-13">
								{item.product__view?.label}
							</Typography>
							<Typography className="break-words whitespace-normal tracking-wide text-grey-500 text-10 ml:text-11 -mt-3">
								{item.product__view?.value}
							</Typography>
						</div>
					);
				},
				key: "product",
				type: "component",
				label: "Stripe Product",
			},
			{
				key: "value",
				label: "Fee (Default)",
				type: "text",
				builder: (item) => feeBuilderFormat(item.value, item.price__view),
			},
			{
				key: "price",
				type: "component",
				label: "Stripe Price (Default)",
				builder: (item) => priceBuilderFormat(item.price__view),
			},
			{
				key: "secondary_value",
				label: "Fee (Secondary)",
				type: "text",
				builder: (item) => feeBuilderFormat(item.secondary_value, item.secondary_price__view),
			},
			{
				key: "secondary_price",
				type: "component",
				label: "Stripe Price (Secondary)",
				builder: (item) => priceBuilderFormat(item.secondary_price__view),
			},
			{
				key: "menu",
				type: "menu",
				label: "Menu",
				items: [
					{
						key: "edit",
						label: "Edit",
						dialogBuilder: (item) => ({
							formId: "ADMIN_PLATFORM_PLAN_FORM",
							mode: "EDIT",
							dataIds: { id: item._id },
						}),
					},
					{
						key: "delete",
						label: "Delete",
						isVisible: (item) => !item?.default,
						dialogBuilder: (item) => ({
							formId: "ADMIN_PLATFORM_PLAN_FORM",
							mode: "DELETE",
							dataIds: { id: item._id },
						}),
					},
				],
			},
		],
	},
};

export default plans;
