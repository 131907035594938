import { CircularProgress, Icon, Typography } from "@material-ui/core";
import { isStepCompleted } from "./onboardUtil";
import { getCarrierId, getUserId } from "app/services/LoginService";
import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import OnboardSubSteps from "./OnboardSubSteps";

function OnboardContent({
	loading,
	setLoading,
	step,
	config,
	data,
	switchToStep,
	onCompleteSteps,
	loadingLastStep,
	props,
}) {
	const dispatch = useDispatch();
	const history = useHistory();

	const dataIds = props.dataIds;
	const isCreateSubAccount = !!dataIds?.createSubAccount;

	const carrierId = isCreateSubAccount ? dataIds?.carrierId : getCarrierId();
	const userId = isCreateSubAccount ? dataIds?.userId : getUserId();
	const activeConfig = useMemo(() => config.list[step], [config, step]);

	const renderHeader = () => {
		const stepCompleted = isStepCompleted({ step, config, data });
		const iconClass = "text-24 md:text-32 mr-6 text-green-400";

		return (
			<div className="flex flex-col w-full items-start justify-center pb-16 ml:pb-24 md:mt-48 md:mb-16">
				<div className="flex flex-row items-center content-center pb-6 relative">
					{stepCompleted && <Icon className={iconClass}>{stepCompleted ? "check_circle" : ""}</Icon>}
					<Typography className="text-20 md:text-28 text-left">{activeConfig.pageTitle}</Typography>
					{loading && (
						<div className="absolute" style={{ right: -50 }}>
							<CircularProgress size={props?.isMobile ? 20 : 30} />
						</div>
					)}
				</div>
				{Array.isArray(activeConfig.pageSubtitle) ? (
					activeConfig.pageSubtitle.map((line, i) => (
						<Typography
							key={"pageSubtitle_" + i}
							color={"textSecondary"}
							className={"text-13 text-left max-w-780 " + (i === 0 ? " -mt-4 " : "")}
						>
							{line}
						</Typography>
					))
				) : (
					<Typography color={"textSecondary"} className="text-13 text-center max-w-780 -mt-4">
						{activeConfig.pageSubtitle}
					</Typography>
				)}
			</div>
		);
	};

	if (!activeConfig) return null;

	return (
		<div className="flex flex-col w-full items-center">
			<div
				className={`flex flex-col items-center w-full mt-0 md:-mt-4 ${
					activeConfig?.classes?.contentContainer ?? "md:w-full"
				}`}
			>
				{renderHeader(step)}
				<div className="flex w-full items-center justify-start">
					{activeConfig?.renderSteps && (
						<OnboardSubSteps
							data={data}
							substepsViews={activeConfig?.renderSteps({
								loading,
								setLoading,
								data,
								carrierId,
								userId,
								config,
								switchToStep,
								onCompleteSteps,
								dispatch,
								history,
								step,
								loadingLastStep,
								props,
							})}
						/>
					)}
					{activeConfig?.render &&
						typeof activeConfig?.render === "function" &&
						activeConfig?.render({
							loading,
							setLoading,
							data,
							carrierId,
							userId,
							config,
							switchToStep,
							onCompleteSteps,
							dispatch,
							history,
							step,
							loadingLastStep,
							props,
						})}
					{activeConfig?.render && typeof activeConfig?.render !== "function" && activeConfig?.render}
				</div>
			</div>
		</div>
	);
}

export default OnboardContent;
