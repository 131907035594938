const createValidationForm = (type, value, newRegistration = false) => ({
	noInitValidation: true,
	items: [
		{
			key: "message",
			type: "message",
			label:
				type === "PHONE"
					? `We sent a validation code to ${value}, please enter it here`
					: `We sent a validation code to ${value}, please enter it here`,
		},
		{
			key: "code",
			type: "text",
			label: "Validation Code",
			required: true,
			maxCharacter: 10,
		},
		...(newRegistration
			? []
			: [
					{ type: "action", label: "Next", action: "SUBMIT" },
					{
						type: "action",
						label: "Back",
						action: "CUSTOM",
						button: {
							color: "primary",
							alwaysEnabled: true,
							classes: { root: "mt-16" },
						},
					},
			  ]),
	],
});

const createValidationTypeForm = (email, phone) => ({
	noInitValidation: true,
	items: [
		{
			key: "message",
			type: "message",
			label: `We need to send you confirmation code to verify validity of the provided information.`,
		},
		{
			key: "validationType",
			type: "radio",
			required: true,
			defaultValue: "EMAIL",
			options: [
				...(email ? [{ value: "EMAIL", label: "Send email to " + email }] : []),
				...(phone ? [{ value: "PHONE", label: "Send SMS to " + phone }] : []),
			],
		},
		{
			type: "action",
			label: "Send Code",
			button: {
				alwaysEnabled: true,
				classes: { root: "mt-8" },
			},
			action: "SUBMIT",
		},
		{
			type: "action",
			label: "Back",
			action: "CUSTOM",
			button: {
				color: "primary",
				alwaysEnabled: true,
				classes: { root: "mt-16" },
			},
		},
	],
});

export { createValidationForm, createValidationTypeForm };
