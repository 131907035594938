// Components
import PlatformAvailablePlansView from "app/main/billing/plans/PlatformAvailablePlansView";
import RegisterForm from "../auth/register/RegisterForm";
import RegisterTermAcknowledgement from "../auth/register/RegisterTermAcknowledgement";
import SignupSidebar from "./SignupSidebar";
import PlatformPlanView from "app/main/billing/plans/PlatformPlanView";
import { setRegisterStatus, setSignupTermsAcknowledgementAccepted } from "app/store/auth/registerSlice";
import { incrementDataRevision } from "app/store/tools/revisionSlice";
import store from "app/store";

const stepConfig = {
	path: "/signup",
	sidebarHeader: "Sign Up",
	renderSidebarDescription: ({ config, data }) => <SignupSidebar config={config} data={data} />,
	sidebarClass: "bg-[#E6E8EA]",
	list: [
		{
			title: "Create Account",
			pageTitle: "Create Your SmartHop Account",
			isAllowed: (data) => !data?.userId, // && data?.authRegister?.status !== "EMAIL_VERIFICATION_REQUIRED",
			isComplete: (data) => !!data?.userId || data?.authRegister?.status === "EMAIL_VERIFICATION_REQUIRED",
			clickNextToComplete: {
				onClick: ({ dispatch }) => {
					dispatch(incrementDataRevision({ event: "registerSubmitRevision" }));
				},
				submitEnabledSelector: (data) => !!data?.auth?.register?.submitRegisterEnabled,
				alwaysResubmit: true,
			},
			render: ({ dispatch, data }) => {
				return data?.verification_token ? (
					<RegisterForm viewType="register" verification_token={data?.verification_token} newRegistration={true} />
				) : (
					<RegisterForm
						forceRegistrationForm={true}
						viewType="register"
						verification_type="EMAIL"
						newRegistration={true}
					/>
				);
			},
		},
		{
			title: "Validate Email",
			pageTitle: "Please Validate Your Email",
			isComplete: (data) => !!data?.userId || !!data?.authRegister?.email_confirmed,
			isAllowed: (data) => data?.authRegister?.status === "EMAIL_VERIFICATION_REQUIRED" && !data?.verification_token,
			hidden: (data) => !!data?.verification_token || data?.urlVerificationToken,
			render: ({ data }) => {
				return <RegisterForm viewType="register" verification_type="EMAIL" newRegistration={true} />;
			},
			clickNextToComplete: {
				submitEnabledSelector: (data) => !!data?.auth?.register?.submitCodeRegisterEnabled,
				onClick: ({ dispatch }) => {
					dispatch(incrementDataRevision({ event: "registerCodeSubmitRevision" }));
				},
			},
		},
		{
			title: "Select Plan",
			pageTitle: "Select Your Plan",
			pageSubtitle: "Select the plan that best fits your needs. Cancel anytime. No hidden fees ever.",
			isComplete: (data) =>
				!!data?.tier ||
				(!!data?.authRegister?.signupSelectedPlan?.planId && !!data?.authRegister?.signupSelectedPlan?.priceId),
			isAllowed: (data) => !!data?.userId,
			hidden: (data) => !!data?.authRegister?.signupSelectedPlan?.urlParams || data?.urlPlanId,
			render: ({ data, carrierId }) => {
				return (
					<div className="flex flex-col w-full items-center justify-center -mt-14">
						<PlatformAvailablePlansView
							newOnboarding={true}
							showPromoCodeBar
							carrierId={carrierId}
							allowToPreselectPlan={true}
							mode="SIGNUP"
							planId={data?.authRegister?.signupSelectedPlan?.planId}
						/>
					</div>
				);
			},
		},
		{
			title: "Acknowledge Terms",
			pageTitle: "Terms Acknowledgement",
			isComplete: (data) => {
				return !!data?.tier || !!data?.authRegister?.signupTermsAcknowledgementAccepted;
			},
			isAllowed: (data) => {
				return (!!data?.userId && !!data?.authRegister?.signupSelectedPlan) || !!data?.tier;
			},
			render: () => {
				return (
					<div className="w-full md:w-10/12">
						<RegisterTermAcknowledgement />
					</div>
				);
			},
			clickNextToComplete: {
				onClick: ({ dispatch }) => {
					dispatch(setSignupTermsAcknowledgementAccepted(true));
				},
			},
		},
		{
			title: "Submit Payment",
			pageTitle: "Enter Your Payment Information",
			isComplete: (data) => !!data?.tier,
			isAllowed: (data) => {
				return (
					!!data?.tier ||
					(!!data?.authRegister?.signupTermsAcknowledgementAccepted &&
						!!data?.authRegister?.signupSelectedPlan?.planId &&
						!!data?.authRegister?.signupSelectedPlan?.priceId)
				);
			},
			render: ({ carrierId, userId, data }) => {
				return (
					<div className="w-full md:w-10/12">
						<PlatformPlanView
							key="signup-submit-payment"
							dataIds={{
								carrierId,
								userId,
								planId: data?.authRegister?.signupSelectedPlan?.planId,
								priceId: data?.authRegister?.signupSelectedPlan?.priceId,
							}}
						/>
					</div>
				);
			},
		},
	],
};

export default stepConfig;
