const handlerField = () => ({
	key: "type",
	type: "select",
	label: "Handler",
	required: true,
	options: [
		{
			value: "STRIPE",
			label: "Stripe",
			showItems: [
				{
					key: "plan",
					type: "autocomplete",
					label: "Plan",
					required: true,
					autocomplete: {
						provider: "smarthop",
						url: "billing/platform-plans-public",
						preloadDefault: true,
						showDescription: true,
					},
				},
				{
					key: "price",
					type: "autocomplete",
					label: "Price",
					required: true,
					autocomplete: {
						provider: "smarthop",
						url: "billing/stripe-prices",
						preloadDefault: true,
						showDescription: true,
					},
					rules: {
						params: {
							valueKey: "plan.product",
							paramKey: "option_product_id_search",
						},
					},
				},
			],
		},
	],
});

const STRIPE_PRODUCT_ID = process.env.REACT_APP_PUBLIC_STRIPE_PRODUCT_ID;

const adminPlatformPlanLinkForm = {
	urlPOST: "billing/platform-plan-link",
	idKey: "_id",
	header: {
		create: { label: "Generate Platform Plan Link" },
	},
	content: {
		create: {
			items: [
				{
					key: "email",
					type: "text",
					label: "Email",
					required: true,
					validation: {
						email: true,
					},
				},
				{
					key: "firstName",
					type: "text",
					label: "Full Name",
				},
				{
					key: "plan",
					type: "autocomplete",
					label: "Plan",
					required: true,
					autocomplete: {
						provider: "smarthop",
						url: "billing/platform-plans-main",
						preloadDefault: true,
						showDescription: true,
					},
				},
				{
					key: "price",
					type: "autocomplete",
					label: "Price",
					required: true,
					autocomplete: {
						provider: "smarthop",
						url: "billing/stripe-prices",
						preloadDefault: true,
						showDescription: true,
						params: {
							option_product_id_search: STRIPE_PRODUCT_ID,
						},
					},
				},
				//handlerField(),
				{
					key: "couponCode",
					type: "text",
					label: "Coupon Code",
				},
				{
					type: "action",
					action: "SUBMIT",
					confirmation: {
						enable: true,
						message: "Are you sure you want to send a signup link with these details?",
					},
					label: "Send Signup Link",
					onSuccess: async (response, { callEndpoint }) => {
						// Create signup URL with form data and verification token
						const signupUrl = response?.signupUrl;
						// Copy to clipboard
						navigator.clipboard.writeText(signupUrl);
						console.log(signupUrl);
					},
				},
			],
		},
	},
};

export default adminPlatformPlanLinkForm;
