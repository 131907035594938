import { getCarrierId, isCarrier } from "app/services/LoginService";
import { incrementDataRevision } from "app/store/tools/revisionSlice";
import { fetchActions } from "app/store/actions/actionsUserSlice";
import { DRIVER_PAY_FIELDS } from "app/main/profile/drivers/EnterprisePayrollSection";

const getUrl = (fromTruck, secondary) => {
	let urlBASE = "api/profile/carriers/:carrierId";

	let urlGET;
	let urlPUT;
	if (!fromTruck) {
		urlGET = urlBASE + "/drivers/:id";
		urlPUT = urlBASE + "/drivers/:id";
		return { urlGET, urlPUT };
	}

	urlGET = urlBASE + "/drivers/from-truck/:id" + (secondary ? "/secondary" : "/primary");
	urlPUT = urlBASE + "/drivers/from-truck/:id" + (secondary ? "/secondary" : "/primary");
	return { urlGET, urlPUT };
};

const ONRAMP_FUEL_ACCOUNT_FIELD = {
	key: "onrampAccountId",
	type: "autocomplete",
	label: "Onramp Fuel Account",
	description: "Search by ID or name",
	autocomplete: {
		provider: "smarthop",
		url: "api/fuel/carriers/:carrierId/onramp-accounts",
		types: [],
		preloadDefault: true,
		showDescription: true,
	},
};

const ONRAMP_FUEL_ACCOUNTS_FIELD = {
	key: "onrampAccounts",
	type: "pattern",
	pattern: { showAllDefault: true, variant: "large" },
	content: {
		items: [
			{
				type: "group",
				content: {
					items: [
						{
							key: "accountId",
							type: "autocomplete",
							label: "Onramp Fuel Account",
							description: "Search by ID or name",
							autocomplete: {
								provider: "smarthop",
								url: "api/fuel/carriers/:carrierId/onramp-accounts",
								types: [],
								preloadDefault: true,
								showDescription: true,
							},
						},
						{
							key: "percentage",
							type: "percentage",
							label: "Percentage",
							translate: "PERCENTAGE",
							min: 0,
							max: 100,
							defaultValue: 100,
						},
					],
				},
			},
		],
	},
};

const DRIVER_CONTACT_FILEDS = [
	{
		type: "group",
		content: {
			items: [{ key: "name", type: "text", label: "Name", required: true }],
		},
	},
	{
		type: "group",
		content: {
			items: [{ key: "email", type: "email", label: "Email", required: true }],
		},
	},
];

const DRIVER_GENERIC_FIELDS = (showSmarFuel) => [
	{
		type: "group",
		content: {
			items: [
				{
					label: "Driver Type",
					key: "driver_type",
					type: "select",
					defaultValue: "COMPANY_DRIVER",
					resource: "DRIVER_FORM_DRIVER_TYPE_POPUP",
					options: [
						{ label: "Company Driver", value: "COMPANY_DRIVER" },
						{ label: "Owner Operator", value: "OWNER_OPERATOR" },
					],
				},
				{
					key: "phone",
					type: "phone",
					label: "Driver Phone",
					description: "Required to send load related comminication via SMS such as dispatched load information",
				},
			],
		},
	},
	showSmarFuel && ONRAMP_FUEL_ACCOUNT_FIELD,
	{
		type: "group",
		content: {
			items: [
				{
					key: "certification_hazmat",
					type: "checkbox",
					label: "Hazmat",
					checkbox: { noErrorMessage: true, variant: "skinny" },
				},
				{
					key: "certification_twic_card",
					type: "checkbox",
					label: "TWIC card",
					checkbox: { noErrorMessage: true, variant: "skinny" },
				},
			],
		},
	},
	{
		type: "group",
		group: { classes: { root: "mb-20" } },
		content: {
			items: [
				{
					key: "certification_tanker_trucking",
					type: "checkbox",
					label: "Tanker Trucking",
					checkbox: { noErrorMessage: true, variant: "skinny" },
				},
				{
					key: "certification_double_triples_trailer",
					type: "checkbox",
					label: "Doubles / Triples Trailers",
					checkbox: { noErrorMessage: true, variant: "skinny" },
				},
			],
		},
	},
	{
		type: "group",
		content: {
			items: [
				{
					key: "cdl_license_number",
					type: "text",
					label: "CDL License Number",
				},
				{ key: "stub_cdl_license", type: "stub" },
			],
		},
	},
	{
		key: "cdl_license",
		type: "upload",
		label: "CDL License Doc",
		upload: { url: "api/profile/carriers/:carrierId/files", documentType: "driver", fileName: "cdl_license" },
	},
	{
		key: "axleId",
		type: "autocomplete",
		label: "Axle Driver ID",
		autocomplete: {
			provider: "smarthop",
			url: "api/profile/carriers/:carrierId/axle/drivers",
			multiple: false,
			preloadDefault: true,
			listenEvent: "profileRevision",
		},
	},
	{
		key: "blacklisted_states_ids",
		type: "autocomplete",
		label: "Blacklisted States",
		autocomplete: {
			provider: "smarthop",
			url: "api/consts/states",
			types: [],
			multiple: true,
			anyValue: false,
			preloadDefault: true,
			icon: "location",
		},
	},
];

const DRIVER_NOTES_FIELD = { key: "notes", type: "text", label: "Notes", multiline: { rows: 4 } };

const DRIVER_ACTION_FIELD = { type: "action", action: "SUBMIT", button: { alwaysEnabledIfValid: true } };

const DRIVER_FIELDS = [...DRIVER_GENERIC_FIELDS(true), DRIVER_NOTES_FIELD];

const driverForm = (fromTruck, secondary, hideDriverPay, showSmarFuel) => {
	const { urlGET, urlPUT } = getUrl(fromTruck, secondary);

	return {
		urlPOST: "api/profile/carriers/:carrierId/drivers",
		urlGET: urlGET,
		urlPUT: urlPUT,
		idKey: "_id",
		triggerEvent: ["fuelRevision", "profileRevision"],
		listenEvent: "profileRevision",
		header: {
			create: { label: "Add New Driver" },
			edit: { label: "Edit Driver" },
			delete: { label: "Delete Driver" },
			view: { label: "View Driver" },
		},
		messages: { edit: { success: "Successfully Saved Information" }, create: { sameAs: "edit" } },
		content: {
			delete: {
				items: [
					{
						type: "message",
						builder: (item) => `Would you like to permanently delete driver '${item.name}' ?`,
					},
					{
						type: "group",
						content: {
							items: [
								{ type: "action", action: "CANCEL" },
								{ type: "action", action: "DELETE" },
							],
						},
					},
				],
			},
			view: {
				sameAs: "edit",
			},
			create: {
				sameAs: "edit",
				onFinished: (_data, dispatch) => {
					if (isCarrier()) {
						dispatch(fetchActions({ carrierId: getCarrierId() }));
					}
					localStorage.setItem("auxFirstDriver", true);
				},
			},
			edit: {
				// Items can mutate based on form params
				items: [
					...DRIVER_CONTACT_FILEDS,
					...DRIVER_GENERIC_FIELDS(showSmarFuel),
					...(hideDriverPay ? [] : DRIVER_PAY_FIELDS),
					DRIVER_NOTES_FIELD,
					DRIVER_ACTION_FIELD,
				],
				onFinished: (_, dispatch) => {
					dispatch(incrementDataRevision({ event: "tripsRevision" }));
				},
			},
		},
	};
};

export default driverForm;

export { driverForm, DRIVER_FIELDS, ONRAMP_FUEL_ACCOUNT_FIELD, ONRAMP_FUEL_ACCOUNTS_FIELD };
