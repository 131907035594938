import RegisterForm from "./RegisterForm";

function RegisterView(props) {
	return (
		<div className="flex flex-col items-center justify-center px-4 mt-12 mb-28 md:mb-8">
			<RegisterForm
				dataIds={props.dataIds}
				onDoneOverride={props.onDone}
				onProgressOverride={props.onProgress}
				onDialogOpenOverride={props.onDialogOpen}
				newRegistration={true} // TODO: remove this once we have the new onboarding flow
			/>
		</div>
	);
}

export default RegisterView;
