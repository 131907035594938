import { useEffect, useRef, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";

import PageWrapper from "app/main/common/PageWrapper";
import { axleTruckImportNew, SmarthopList } from "@smarthop/list";
import { showSnackbar } from "app/main/utils/snackbarUtil";
import { importAxleTrucks } from "app/services/carrierServices";
import { getCarrierId } from "app/services/LoginService";
import { setSetupTruckAxleSyncSubmitEnabled } from "app/store/auth/registerSlice";
import { incrementDataRevision } from "app/store/tools/revisionSlice";

export default function SyncTrucks({ setupCarrierId, stepData, loading, setLoading, switchToStep }) {
	const carrierId = setupCarrierId ?? getCarrierId();

	const dispatch = useDispatch();
	const snackbar = useSnackbar();
	const config = useMemo(() => axleTruckImportNew(loading, setLoading), [loading, setLoading]);

	const submitRevision = useSelector(({ tools }) => tools.revision["setupTruckAxleSyncSubmitRevision"]);
	const submitRevisionRef = useRef(submitRevision);

	const trucks = useMemo(() => {
		return stepData?.axleTrucks?.items?.filter?.((d) => !d.deleted && !d.imported) ?? [];
	}, [stepData?.axleTrucks]);

	const onSubmitData = useCallback(async () => {
		if (!trucks?.length) {
			return switchToStep(2);
		}
		setLoading(true);
		importAxleTrucks(
			carrierId,
			trucks.map((d) => d.id)
		)
			.then((results) => {
				const failedToSave = Object.keys(results).filter((id) => {
					return results[id] !== true;
				});
				const recordsSaved = Object.keys(results).filter((id) => {
					return results[id] === true;
				});

				if (failedToSave.length > 0) {
					showSnackbar(
						snackbar,
						(recordsSaved.length > 0 ? `${recordsSaved.length} Records imported. ` : "") +
							`${failedToSave.length} Records failed to import: ${results[failedToSave[0]]}`,
						"error"
					);
					dispatch(incrementDataRevision({ event: "setupDataRevision" }));
					dispatch(incrementDataRevision({ event: "profileRevision" }));
				} else {
					showSnackbar(snackbar, `${recordsSaved.length} Records Imported`, "success");
					dispatch(incrementDataRevision({ event: "profileRevision" }));
					dispatch(incrementDataRevision({ event: "setupRevision" }));
					dispatch(incrementDataRevision({ event: "setupDataRevision" }));
				}
			})
			.finally(() => setLoading(false));
	}, [trucks, dispatch, setLoading, carrierId, switchToStep, snackbar]);

	const submitEnabled = useMemo(() => {
		return !!trucks?.length || stepData?.truck_complete;
	}, [trucks, stepData]);

	useEffect(() => {
		if (submitRevision && submitRevision !== submitRevisionRef.current && submitEnabled) {
			submitRevisionRef.current = submitRevision;
			onSubmitData();
		}
		// eslint-disable-next-line
	}, [submitRevision]);

	useEffect(() => {
		dispatch(setSetupTruckAxleSyncSubmitEnabled(submitEnabled));
	}, [dispatch, submitEnabled]);

	return (
		<PageWrapper
			classes={{ content: "" }}
			minimalView={true}
			titleActions={[
				{
					action: "add_truck",
					label: "Add Truck",
					dialog: { formId: "AXLE_SYNC_TRUCK_ADD", mode: "CREATE", dataIds: { carrierId } },
				},
			]}
		>
			<SmarthopList
				isView={true}
				urlKey="importerStepTruck"
				key="importerStepTruck"
				mode="TABLE"
				config={config}
				dataIds={{ carrierId }}
			/>
		</PageWrapper>
	);
}
