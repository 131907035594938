import { Button, Icon } from "@material-ui/core";
import { hasPrevStep, hasNextStep, isStepAllowed } from "./onboardUtil";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

function OnboardFooter({
	step,
	config,
	data,
	switchToStep,
	setMobileSidebarOpen,
	mobileSidebarOpen,
	loading,
	setLoading,
	extraFooterPadding,
	props,
}) {
	const dispatch = useDispatch();
	const navDisabled = useMemo(() => config.navDisabled?.({ config, data }), [config, data]);
	config.list = config.list.filter((list) => {
		if (list.hidden && list.hidden(data, props)) {
			return false;
		}
		return true;
	});

	const nextToCompleteSettings = config?.list?.[step]?.clickNextToComplete;
	const submitEnabledSelector = nextToCompleteSettings?.submitEnabledSelector;
	const submitEnabled = useSelector(submitEnabledSelector ? submitEnabledSelector : () => true);
	const nextToCompleteEnabled = useMemo(
		() => nextToCompleteSettings?.enabled && nextToCompleteSettings?.enabled({ dispatch, data }),
		[nextToCompleteSettings, data, dispatch]
	);

	const hasNext = useMemo(
		() => hasNextStep({ step, config, data }) && isStepAllowed({ step: step + 1, config, data }),
		[step, config, data]
	);

	const clickNextToComplete = useMemo(() => {
		return (
			!!nextToCompleteSettings && (!config?.list?.[step]?.isComplete(data) || nextToCompleteSettings?.alwaysResubmit)
		);
	}, [step, config, nextToCompleteSettings, data]);

	const hasPrev = useMemo(
		() => hasPrevStep({ step, config, data }) && isStepAllowed({ step: step - 1, config, data }),
		[step, config, data]
	);

	const btnFooter = config?.list?.[step]?.btnFooter?.(
		dispatch,
		data,
		switchToStep,
		loading,
		setLoading,
		props.isMobile
	);
	if (btnFooter?.length > 0) {
		return (
			<div
				className={`flex flex-row bg-gray-200 py-16 px-16 md:justify-end justify-between space-x-16 md:mr-20 ${
					extraFooterPadding ? "pb-48" : ""
				}`}
			>
				{btnFooter.map((btn) => {
					return (
						<Button
							variant="contained"
							color={btn.color}
							onClick={() => btn.onClick()}
							disabled={btn.disabled}
							className={btn.style}
						>
							{btn.title}
						</Button>
					);
				})}
			</div>
		);
	}

	const btnClassNext = "whitespace-nowrap px-40 md:px-72 text-14 md:text-14 font-bold py-6 text-white";
	const btnClassPrev = "whitespace-nowrap px-40 md:px-72 text-14 md:text-14 font-bold py-6 bg-white text-black";

	return (
		<div
			className={`flex flex-row bg-gray-200 py-16 px-16 md:justify-end justify-between space-x-16 md:mr-20 ${
				extraFooterPadding ? "pb-48" : ""
			}`}
		>
			<Button
				variant="contained"
				disabled={!hasPrev || navDisabled || loading}
				onClick={() => {
					if (!navDisabled) {
						switchToStep(step - 1);
					}
				}}
				className={btnClassPrev}
			>
				{"< Back"}
			</Button>
			{(props.isMobile || props.nativeMobile) && !mobileSidebarOpen && (
				<Button
					variant="contained"
					disabled={navDisabled || loading}
					onClick={() => !navDisabled && setMobileSidebarOpen(true)}
					className="whitespace-nowrap text-14 md:text-14 font-bold py-6 px-16 flex"
				>
					<Icon>menu</Icon>
				</Button>
			)}
			{!clickNextToComplete && (
				<Button
					disabled={!hasNext || loading}
					variant="contained"
					color="default"
					onClick={() => switchToStep(step + 1)}
					className={btnClassNext + (hasNext ? " animate-pulse" : "")}
				>
					{" Next >"}
				</Button>
			)}
			{clickNextToComplete && (
				<Button
					variant="contained"
					color="secondary"
					disabled={!submitEnabled || loading}
					onClick={() => nextToCompleteSettings?.onClick({ dispatch, data })}
					className={btnClassNext + (nextToCompleteEnabled ? "animate-pulse" : "")}
				>
					{" Next >"}
				</Button>
			)}
		</div>
	);
}

export default OnboardFooter;
