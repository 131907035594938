/**
 * Authorization Roles
 */
const authRoles = {
	everyone: ["everyone"],
	guest: [],
	// Misc
	bot: ["bot"],
	deactivated: ["deactivated"],
	all: [
		"administrator",
		"dispatcher",
		"ops support",
		"broker",
		"reporter",
		"carrier",
		"driver",
		"carrier_manager",
		"carrier_dispatcher",
		"carrier_generic",
	],
	allMessagingUsers: [
		"administrator",
		"dispatcher",
		"ops support",
		"broker",
		"reporter",
		"carrier",
		"driver",
		"carrier_generic",
		"carrier_manager",
		"carrier_dispatcher",
	],
	// Internal
	admin: ["administrator"],
	dispatcher: ["dispatcher"],
	internalPowerUsers: ["administrator", "ops support"],
	internalDispatchingUsers: ["administrator", "ops support", "dispatcher"],
	internalUsers: ["administrator", "ops support", "dispatcher"],
	// External
	carrier: ["carrier"],
	driver: ["driver"],
	carrierManager: ["carrier_manager"],
	carrierDispatcher: ["carrier_dispatcher"],
	carrierGeneric: ["carrier_generic"],
	carrierPowerUsers: ["carrier", "carrier_manager"],
	externalDispatchingUsers: ["carrier", "carrier_manager", "carrier_dispatcher"],
	externalUsers: ["carrier", "driver", "carrier_manager", "carrier_dispatcher", "carrier_generic"],
};

export default authRoles;
