import { useState, useEffect } from "react";
import FusePageSimple from "@fuse/core/FusePageSimple";
import PageTitle from "./PageTitle";
import { useDispatch, useSelector } from "react-redux";
import { openTutorialDialog } from "app/store/tools/tutorialDialogSlice";
import { typeWarning, showWarning } from "app/main/utils/warningUtils";
import { showTutorial } from "app/main/utils/tutorialUtils";
import { getUserTier, isRoleExternal } from "app/services/LoginService";

function PageWrapper(props) {
	const dispatch = useDispatch();
	const user = useSelector(({ auth }) => auth.user);

	const warning = typeWarning(props.restrictions, user, props.requiredGateKeepers);
	const isExternalUser = isRoleExternal();
	const reactNative = window?.ReactNativeWebView ?? false;

	const planChangeRevision = useSelector(({ tools }) => tools.revision["planChangeRevision"]);
	const planChangeRevisionSoft = useSelector(({ tools }) => tools.revision["planChangeRevisionSoft"]);
	const [userTier, setUserTier] = useState(getUserTier());

	useEffect(() => {
		if (userTier !== getUserTier()) {
			window.location.reload();
			setUserTier(getUserTier());
		}
		// eslint-disable-next-line
	}, [planChangeRevision]);

	useEffect(() => {
		if (userTier !== getUserTier()) {
			// Do not reload page in case of soft reload
			setUserTier(getUserTier());
		}
		// eslint-disable-next-line
	}, [planChangeRevisionSoft]);

	const { resource } = props;
	if (warning) return showWarning(warning, props.title, props.classes, props.restrictions, resource?.onboarding);
	const hasToolbarCompontents = props.title || props.titleActions || props.toolbarView || props.toolbarChildren;

	if (!reactNative && isExternalUser && resource?.tutorial && showTutorial(resource?.tutorial, user)) {
		dispatch(openTutorialDialog({ tutorialId: resource?.tutorial }));
	}

	const contentHeaderView = hasToolbarCompontents && (
		<>
			<div className={"w-full flex flex-row flex-1 items-center " + (props.isView ? "" : " pt-4 ")}>
				{props.toolbarView}
				<div className={"flex flex-col flex-1 items-center justify-between " + (props.isView ? "" : " pt-14 pb-8 ")}>
					<PageTitle
						text={props.isView ? "" : props.title}
						view={props.titleView}
						actions={props.titleActions}
						resource={props?.resource}
					>
						{props.toolbarChildren && (
							<div className="flex flex-1 mr-10 flex-col justify-center min-h-48">{props.toolbarChildren}</div>
						)}
						{props.toolbarComponent && <div className="flex ml-10 min-h-48">{props.toolbarComponent}</div>}
					</PageTitle>
				</div>
			</div>
		</>
	);

	const contentChildrenView = <div className="flex w-full h-full flex-col">{props.children}</div>;

	if (props?.minimalView) {
		return (
			<div className="flex w-full h-full flex-col mx-auto -mt-68">
				{contentHeaderView}
				{contentChildrenView}
			</div>
		);
	}

	if (props?.noFuseWrapper || props.isView) {
		return (
			<div className="flex w-full h-full flex-col mx-auto px-6">
				{contentHeaderView}
				{contentChildrenView}
			</div>
		);
	}

	return (
		<FusePageSimple
			noPadding={props.variant === "full" || props?.noPadding}
			classes={{
				root: props.classes?.root,
				wrapper: props.classes?.wrapper ?? "bg-transparent",
				content:
					props.classes?.content ??
					`w-full mx-auto ${props?.noPadding ? "" : "md:px-0 px-8"} ` +
						(props.variant === "full"
							? ""
							: props.variant === "narrow"
							? " max-w-2xl "
							: props.variant === "extra-narrow"
							? " max-w-xl "
							: ""),
				toolbar:
					props.classes?.toolbar ??
					"w-full mx-auto relative flex flex-col min-h-auto h-auto items-start px-8 md:px-0 " +
						(props.variant === "full"
							? ""
							: props.variant === "narrow"
							? " max-w-2xl "
							: props.variant === "extra-narrow"
							? " max-w-xl "
							: ""),
			}}
			contentToolbar={contentHeaderView}
			content={contentChildrenView}
		/>
	);
}

export default PageWrapper;
