import { useDispatch } from "react-redux";
import { Typography } from "@material-ui/core";

// Sevices
import { getLoadForDispatcherRefreshed } from "app/services/searchServices";

// Tools
import { incrementDataRevision } from "app/store/tools/revisionSlice";

// Utils
import { formatDate, formatAge } from "app/main/utils/dateUtils";

// Segment
import { LOAD_CALL_TO_ACTION } from "app/main/segment/segmentType";
import { createTrackOrPage } from "app/main/segment/segmentEvent";

const getBrokerContact = (item, smallScreen) => {
	// Label
	let lContact = item.contact;
	if (lContact?.length > 14 && smallScreen) lContact = lContact.substring(0, 12) + "...";

	// Functionality (email or phone)
	let contact = item.contact;
	if (contact?.includes("@")) {
		const newLine = `%0D%0A`;

		const { load_id, origin, destination, ready, hasBrokerId } = item;
		const mailSubject = hasBrokerId ? `Load ${load_id}` : `Load from ${origin} to ${destination}`;
		const mailBody = `Hi,${newLine}${newLine}I'm interested in load ${
			hasBrokerId ? `ID ${load_id}` : ""
		} from ${origin} to ${destination} on ${formatDate(ready)}.${newLine}${newLine}Thanks`;
		return { label: lContact, ref: `mailto:${contact}?subject=${mailSubject}&body=${mailBody}` };
	} else {
		if (!contact?.includes("+1")) contact = "+1" + contact;
		return { label: lContact, ref: "tel:" + contact };
	}
};

function ContactComponent(props) {
	const dispatch = useDispatch();

	const item = props.item;
	const userId = props.userId;
	const searchId = props.searchId;
	const smallScreen = props.smallScreen;

	const booknow = item.booknow;
	const bidnow = item.bidnow;
	if (booknow || bidnow) {
		return (
			<Typography className={"text-11 ml:text-12 whitespace-nowrap text-grey min-w-40 ml:min-w-92 xl:min-w-180"}>
				{bidnow ? "Bid Online" : booknow ? "Book Online" : ""}
			</Typography>
		);
	}

	const contact = item.contact;
	if (!contact) return null;

	const { label, ref } = getBrokerContact(item, smallScreen);
	const onClickBroker = (event) => {
		event?.stopPropagation();

		// TODO: searchId always is EMPTY
		const options = { tollDiscouraged: true };
		getLoadForDispatcherRefreshed(userId, searchId, item.tripid, options).then((load) => {
			if (load.deleted || !load.available) {
				dispatch(incrementDataRevision({ event: "searchResultsChange" + searchId }));
			}
			const data = {
				owner: item.owner,
				clientid: item.clientid,
				rank: item.rank,
				age: formatAge(item.updated),
				best: item.best,
				isEmail: item.contact?.includes("@"),
				hasAvailabilityCheck: item.hasAvailabilityCheck,
				covered: load.deleted || !load.available,
			};
			createTrackOrPage(LOAD_CALL_TO_ACTION, data, "track");
		});
	};

	return (
		<a href={ref} onClick={(event) => onClickBroker(event)}>
			<Typography
				className={
					"text-11 ml:text-12 whitespace-nowrap text-grey no-underline hover:underline truncate max-w-1000 ml:max-w-150 xl:max-w-200 "
				}
			>
				{label}
			</Typography>
		</a>
	);
}

export default ContactComponent;
