import { CircularProgress, Icon } from "@material-ui/core";
import { TRAILER_LITE_FIELDS, TRAILER_LENGTH_FIELD } from "@smarthop/form/configs/trailerForm";
import { deleteTruckAxleImport, updateTruckAxleImport } from "app/services/carrierServices";
import { incrementDataRevision } from "app/store/tools/revisionSlice";

const axleTruckImportNew = (loading, setLoading) => ({
	urlGET: "api/axle_import/carriers/:carrierId/trucks",
	idKey: "id",
	listenEvent: "profileRevision",
	segmentKey: "AXLE_TRUCK_IMPORT",
	content: {
		pagination: {
			enabled: false,
			resetScroll: true,
		},
		table: {
			variant: "skinny",
		},
		order: {
			defaut: {
				key: "name",
				dir: "asc",
			},
		},
		defaults: {
			initial: {
				filters: {
					import_status: "__IMPORTABLE__",
				},
			},
		},
		/* filters:  {
			search: true,
			items: [
				{
					key: "import_status",
					type: "select",
					label: "Import Status",
					defaultValue: "__NOT_SELECTED__",
					options: [
						{
							value: "__NOT_SELECTED__",
							label: "Not Selected",
						},
						{
							value: "__IMPORTABLE__",
							label: "Imported & Records to import",
						},
						{
							value: "__DELETED__",
							label: "Don't Import",
						},
					],
				},
			],
		},*/
		items: [
			{
				key: "name",
				type: (item) => (item.imported ? "truck__view" : "text"),
				label: "Truck",
				builder: (item) => {
					if (item.imported) {
						return item.truck__view;
					}

					return (
						<div className="flex flex-col">
							<div>{item.name}</div>
							<div className="text-10 flex flex-row space-x-4">
								{item.model && <div>{`${item.model} ${item.model_year}`}</div>}
								{item.vin && <div>{`#${item.vin}`}</div>}
							</div>
						</div>
					);
				},
			},
			{
				key: "equipment",
				type: "text",
				label: "Type",
				builder: (item) => (!!item.imported || !!item.deleted ? item.equipment.replace("SPECIAL_", "") : ""),
				editable: (item) => !item.imported && !item.deleted,
				edit: {
					suppressSnackbar: true,
					urlPUT: "api/axle_import/carriers/:carrierId/axle_truck/:axleTruckId",
					triggerEvent: ["profileRevision", "setupDataRevision"],
					field: {
						...TRAILER_LITE_FIELDS(false)[0],
						classes: { cell: "min-w-192" },
					},
					paramsBuilder: (item, dataIds) => {
						const data = {
							data: item,
							dataIds: {
								carrierId: dataIds.carrierId,
								axleTruckId: item.id,
							},
						};
						return data;
					},
				},
			},
			{
				key: "trailer",
				type: "text",
				label: "Capacity",
				builder: (item) => (item.equipment === "PO" ? "-" : item.imported || item.deleted ? item.trailer : ""),
				editable: (item) => !item.imported && !item.deleted && item.equipment !== "PO",
				edit: {
					suppressSnackbar: true,
					urlPUT: "api/axle_import/carriers/:carrierId/axle_truck/:axleTruckId",
					triggerEvent: ["profileRevision", "setupDataRevision"],
					field: {
						...TRAILER_LENGTH_FIELD(false),
						classes: { cell: "min-w-192" },
					},
					paramsBuilder: (item, dataIds) => {
						const data = {
							data: item,
							dataIds: {
								carrierId: dataIds.carrierId,
								axleTruckId: item.id,
							},
						};
						return data;
					},
				},
			},
			{
				key: "import_status",
				type: "text",
				label: "Import Status",
				builder: (item) => {
					return item.imported ? (
						<span className="text-green">Imported</span>
					) : item.deleted ? (
						<span className="text-red">Skipped</span>
					) : (
						<span className="text-grey">To be Imported</span>
					);
				},
			},
			{
				type: "menu",
				items: [
					{
						key: "delete",
						label: "Don't Import",
						variant: "iconButton",
						isVisible: (item) => !item.deleted && !item.imported,
						button: {
							icon: "delete",
							color: "primary",
							simulateLoading: true,
						},
						dispatchAction: (item, dataIds, dispatch, history, snackbar) => {
							deleteTruckAxleImport(dataIds.carrierId, item.id).then(() => {
								dispatch(incrementDataRevision({ event: "profileRevision" }));
								dispatch(incrementDataRevision({ event: "setupDataRevision" }));
							});
						},
					},
					{
						key: "undo",
						label: "Add",
						variant: "iconButton",
						isVisible: (item) => !!item.deleted && !item.imported,
						button: {
							icon: "add",
							color: "primary",
							simulateLoading: true,
						},
						dispatchAction: (item, dataIds, dispatch, history, snackbar) => {
							updateTruckAxleImport(dataIds.carrierId, item.id, { deleted: false }).then(() => {
								dispatch(incrementDataRevision({ event: "profileRevision" }));
								dispatch(incrementDataRevision({ event: "setupDataRevision" }));
							});
						},
					},
				],
			},
		],
	},
});

export default axleTruckImportNew;
