import { createRequest, throwResErrors, createHeaders } from "./requestUtil";
import store from "app/store";
import { setFeatures } from "app/store/feature/featureSlice";

export const update = (featureName, status, userIds = []) => {
	return createRequest()
		.put(`api/feature/${featureName}`, { status, userIds }, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const remove = (featureName) => {
	return createRequest()
		.delete(`api/feature/${featureName}`, {}, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const isEnabled = (feature) => {
	let featureList = store.getState()?.feature ?? null;

	if (!featureList) {
		featureList = localStorage.getItem("features");
		if (featureList) {
			featureList = JSON.parse(featureList);
		} else {
			featureList = {};
		}
	}

	if (!featureList.hasOwnProperty(feature)) {
		console.error(`Invalid feature ${feature}`);
		return false;
	}

	return featureList[feature];
};

export const set = (featuresList) => {
	localStorage.setItem("features", JSON.stringify(featuresList));
	store.dispatch(setFeatures(featuresList));
};

export const getDefaultGatekeepers = async () => {
	return createRequest()
		.get(`api/gatekeeper/list`, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};
