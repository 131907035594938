import { Icon, Typography } from "@material-ui/core";
import * as yup from "yup";
import {
	DRIVER_PAY_FIELDS,
	DRIVER_PAYROLL_FIELDS,
	DISPATCHER_PAYROLL_FIELDS,
	INVESTOR_PAYROLL_FIELDS,
} from "app/main/profile/drivers/EnterprisePayrollSection";
import {
	ROLE_OWNER_KEY,
	ROLE_MANAGER_KEY,
	ROLE_DISPATCHER_KEY,
	ROLE_INVESTOR_KEY,
	ROLE_DRIVER_KEY,
	PERMISSION_BILLING_ACCESS,
	PERMISSION_USER_ACCESS,
	PERMISSION_FLEET_CONFIG_ACCESS,
	PERMISSION_PAYROLL_ACCESS,
	PERMISSION_INVOICE_ACCESS,
	PERMISSION_SEARCH_ACCESS_ON,
	PERMISSION_MESSAGING_ON,
	PERMISSION_INSTANT_BOOK_ON,
	PERMISSION_TRIPS_ACCESS,
	PERMISSION_RATES_ON,
	PERMISSION_PERFORMANCE_ACCESS,
	permissionBoolTrue,
	permissionAdmin,
	permissionEdit,
	permissionView,
	getUserRolesLabel,
	getTruckAccessType,
	getPermissionsCounters,
} from "./userRolesUtils";
import { DRIVER_FIELDS, ONRAMP_FUEL_ACCOUNTS_FIELD } from "@smarthop/form/configs/driverForm";
import { updateConfig } from "@smarthop/utils/fieldsHelpers";
import SmarthopPreviewField from "@smarthop/form/fields/SmarthopPreviewField";
import SmarthopFormView from "@smarthop/form/SmarthopFormView";
import TooltipClickAwayView from "app/main/tools/TooltipClickAwayView";
import { isEnabled } from "app/services/featureStorageService";

const INVESTOR_PROFILE_FIELDS = [ONRAMP_FUEL_ACCOUNTS_FIELD];

const USER_FIELDS = (mode, canBeDectivated, canBeEditEmail) => [
	...(mode === "EDIT"
		? [
				{
					type: "section",
					label: "User",
					section: { icon: "account_circle", classes: { root: " mt-4" } },
				},
		  ]
		: []),
	{
		key: "users",
		type: "pattern",
		label: "",
		pattern: {
			min: 1,
			disableMoveButton: true,
			disableInsertButtons: true,
			disablDeleteButton: mode === "EDIT" || mode === "VIEW",
			disablAddButton: mode === "EDIT" || mode === "VIEW",
			hideIndex: mode === "EDIT" || mode === "VIEW",
		},
		content: {
			items: [
				{
					type: "group",
					content: {
						items: [
							{
								key: "email",
								type: "email",
								label: "Email",
								// Users that can not be deactivated usually admins, for such users
								// we need to disable ability to edit names by anyone except that user
								disabled: (!canBeDectivated || !canBeEditEmail) && mode === "EDIT",
								required: true,
							},

							{
								key: "first_name",
								type: "text",
								label: "First Name",
								// Users that can not be deactivated usually admins, for such users
								// we need to disable ability to edit names by anyone except that user
								disabled: !canBeDectivated && mode === "EDIT",
								required: true,
							},
							{
								key: "last_name",
								type: "text",
								label: "Last Name",
								// Users that can not be deactivated usually admins, for such users
								// we need to disable ability to edit names by anyone except that user
								disabled: !canBeDectivated && mode === "EDIT",
								required: true,
							},
						],
					},
				},
			],
		},
	},
	...(mode === "EDIT" && !canBeEditEmail
		? [
				{
					key: "edit_email_info",
					type: "message",
					message: { classes: { root: " ml-10 mr-16 -mt-16 " } },
					builder: () => (
						<Typography className="text-grey-700">* The user can modify their email from their own profile</Typography>
					),
				},
		  ]
		: []),
];

const USER_LITE_FIELDS = () => [
	{
		key: "first_name",
		type: "text",
		label: "First Name",
		required: true,
	},
	{
		key: "last_name",
		type: "text",
		label: "Last Name",
		required: true,
	},
	{
		key: "phone",
		type: "phone",
		label: "Driver Phone",
		description: "Required to send load related comminication via SMS such as dispatched load information",
	},
	{
		key: "email",
		type: "email",
		label: "Email",
		required: true,
	},
];

const ROLE_FIELDS = ({ viewerCanEditRole, enabledRoles, hasUsersAccessPermission }) => {
	const enabledAll = !enabledRoles?.length;
	const roles = [
		{
			type: "group",
			content: {
				items: [
					{
						key: ROLE_OWNER_KEY,
						type: "checkbox",
						label: "Owner",
						disabled: true,
						checkbox: { noErrorMessage: true, variant: "skinny" },
					},
					{
						key: ROLE_INVESTOR_KEY,
						type: "checkbox",
						label: "Investor",
						disabled:
							!viewerCanEditRole ||
							!hasUsersAccessPermission ||
							(!enabledAll && !enabledRoles?.includes?.(ROLE_INVESTOR_KEY)),
						checkbox: { noErrorMessage: true, variant: "skinny" },
					},
				],
			},
		},
		{
			type: "group",
			content: {
				items: [
					{
						key: ROLE_MANAGER_KEY,
						type: "checkbox",
						label: "Manager",
						disabled:
							!viewerCanEditRole ||
							!hasUsersAccessPermission ||
							(!enabledAll && !enabledRoles?.includes?.(ROLE_MANAGER_KEY)),
						checkbox: { noErrorMessage: true, variant: "skinny" },
					},
					{
						key: ROLE_DRIVER_KEY,
						type: "checkbox",
						label: "Driver",
						disabled: !viewerCanEditRole || (!enabledAll && !enabledRoles?.includes?.(ROLE_DRIVER_KEY)),
						checkbox: { noErrorMessage: true, variant: "skinny" },
					},
				],
			},
		},
		{
			type: "group",
			content: {
				items: [
					{
						key: ROLE_DISPATCHER_KEY,
						type: "checkbox",
						label: "Dispatcher",
						disabled:
							!viewerCanEditRole ||
							!hasUsersAccessPermission ||
							(!enabledAll && !enabledRoles?.includes?.(ROLE_DISPATCHER_KEY)),
						checkbox: { noErrorMessage: true, variant: "skinny" },
					},
				],
			},
		},
	];

	return roles;
};

const PERMISSIONS_LEFT = (enabled, config, payrollEnabled, invoiceEnabled) => {
	const permissions = [
		{
			key: permissionEdit(PERMISSION_BILLING_ACCESS),
			type: "checkbox",
			label: "Allow Manage Billing",
			disabled: true,
			checkbox: { noErrorMessage: true, variant: "skinny" },
		},
		{
			key: permissionBoolTrue(PERMISSION_USER_ACCESS),
			type: "checkbox",
			label: "Allow Manage Users",
			checkbox: { noErrorMessage: true, variant: "skinny" },
		},
		{
			key: permissionBoolTrue(PERMISSION_FLEET_CONFIG_ACCESS),
			type: "checkbox",
			label: "Allow Manage Fleet Config " + invoiceEnabled,
			checkbox: { noErrorMessage: true, variant: "skinny" },
		},
		...(payrollEnabled
			? [
					{
						key: permissionView(PERMISSION_PAYROLL_ACCESS),
						type: "checkbox",
						label: "Allow Access Payroll",
						checkbox: { noErrorMessage: true, variant: "skinny" },
					},
					{
						key: permissionEdit(PERMISSION_PAYROLL_ACCESS),
						type: "checkbox",
						label: "Allow Edit Payroll",
						checkbox: { noErrorMessage: true, variant: "skinny", classes: { root: "ml-28" } },
					},
					{
						key: permissionAdmin(PERMISSION_PAYROLL_ACCESS),
						type: "checkbox",
						label: "Allow Close Payroll",
						checkbox: { noErrorMessage: true, variant: "skinny", classes: { root: "ml-28" } },
					},
			  ]
			: []),
		...(invoiceEnabled
			? [
					{
						key: permissionView(PERMISSION_INVOICE_ACCESS),
						type: "checkbox",
						label: "Allow Access Invoices",
						checkbox: { noErrorMessage: true, variant: "skinny" },
					},
					{
						key: permissionEdit(PERMISSION_INVOICE_ACCESS),
						type: "checkbox",
						label: "Allow Edit Invoices",
						checkbox: { noErrorMessage: true, variant: "skinny", classes: { root: "ml-28" } },
					},
			  ]
			: []),
	];

	permissions.forEach((item) => {
		item.disabled = !enabled?.includes?.(item.key);
		item.checkbox.disclaimer = config?.[item.key]?.disclaimer;
	});

	return permissions;
};

const PERMISSIONS_RIGHT = (enabled, config) => {
	const permissions = [
		{
			key: permissionBoolTrue(PERMISSION_SEARCH_ACCESS_ON),
			type: "checkbox",
			label: "Allow Access Search",
			checkbox: { noErrorMessage: true, variant: "skinny" },
		},
		{
			key: permissionBoolTrue(PERMISSION_MESSAGING_ON),
			type: "checkbox",
			label: "Allow Access Dispatching Channel",
			checkbox: { noErrorMessage: true, variant: "skinny", classes: { root: "ml-28" } },
		},
		{
			key: permissionBoolTrue(PERMISSION_INSTANT_BOOK_ON),
			type: "checkbox",
			label: "Allow Instant Book and Bid",
			checkbox: { noErrorMessage: true, variant: "skinny", classes: { root: "ml-28" } },
		},
		{
			key: permissionView(PERMISSION_TRIPS_ACCESS),
			type: "checkbox",
			label: "Allow Trips Access",
			checkbox: { noErrorMessage: true, variant: "skinny" },
		},
		{
			key: permissionBoolTrue(PERMISSION_RATES_ON),
			type: "checkbox",
			label: "Allow View Rate and RC",
			checkbox: { noErrorMessage: true, variant: "skinny", classes: { root: "ml-28" } },
		},
		{
			key: permissionEdit(PERMISSION_TRIPS_ACCESS),
			type: "checkbox",
			label: "Allow Edit Trips",
			checkbox: { noErrorMessage: true, variant: "skinny", classes: { root: "ml-28" } },
		},
		{
			key: permissionView(PERMISSION_PERFORMANCE_ACCESS),
			type: "checkbox",
			label: "Allow Access Performance Analytics",
			checkbox: { noErrorMessage: true, variant: "skinny" },
		},
	];

	permissions.forEach((item) => {
		item.disabled = !enabled?.includes?.(item.key);
		item.checkbox.disclaimer = config?.[item.key]?.disclaimer;
	});

	return permissions;
};

const PERMISSION_SECTION = ({ enabledPermissions, configsPermissions, payrollEnabled, invoiceEnabled }) => ({
	key: "permissions",
	type: "object",
	content: {
		items: [
			{
				type: "group",
				group: { classes: { root: "items-top" } },
				content: {
					items: [
						{
							type: "group",
							group: { variant: "vertical" },
							content: {
								items: PERMISSIONS_LEFT(enabledPermissions, configsPermissions, payrollEnabled, invoiceEnabled),
							},
						},
						{
							type: "group",
							group: { variant: "vertical" },
							content: {
								items: PERMISSIONS_RIGHT(enabledPermissions, configsPermissions, payrollEnabled, invoiceEnabled),
							},
						},
					],
				},
			},
		],
	},
});

const WEB_PROFILE_SECTION = ({
	enabledPermissions,
	accessDeactivated,
	configsPermissions,
	payrollEnabled,
	invoiceEnabled,
	isEnabledSMS,
}) => {
	return [
		{
			key: "role",
			type: "text",
			label: "Role",
			builder: (data) => {
				return getUserRolesLabel(data.roles);
			},
		},
		{
			key: "permissions",
			type: "component",
			value: (data) => {
				const counters = getPermissionsCounters(data?.permissions);
				return (
					<SmarthopPreviewField
						type={"text"}
						label={"Permissions"}
						component={{ preview: { variant: "row" } }}
						value={
							accessDeactivated ? "Access Disabled" : counters?.viewCount + " View, " + counters?.editCount + " Edit"
						}
					>
						<TooltipClickAwayView
							classes={{ tooltip: "drop-shadow-md bg-white border-1 border-grey min-w-300" }}
							title={
								!accessDeactivated ? (
									<div className="px-10 pb-12">
										<SmarthopFormView
											content={{
												items: [
													PERMISSION_SECTION({
														enabledPermissions,
														configsPermissions,
														payrollEnabled,
														invoiceEnabled,
													}),
												],
											}}
											data={data}
											mode={"VIEW"}
											overrides={{
												group: { component: { variant: "vertical", classes: null } },
												ALL_EDITABLE: { component: { preview: { variant: "row" } } },
											}}
										/>
									</div>
								) : (
									<Typography className={"text-13 text-black py-10 px-10"}>
										Access restricted. This user can not access web portal.
									</Typography>
								)
							}
						>
							<Icon className="text-15 -mb-2 ml-3">help_outline</Icon>
						</TooltipClickAwayView>
					</SmarthopPreviewField>
				);
			},
		},
		...(isEnabledSMS
			? [
					{
						key: "sms_notify_opt_in",
						type: "text",
						description: "Get notifications and chat messages VIA SMS if the user does not have the app installed.",
						label: "SMS Notifications",
						builder: (item) =>
							item.sms_notify_opt_out ? "User unsubsribed from SMS" : !item.sms_notify_opt_in ? "No" : "Yes",
					},
			  ]
			: []),
	];
};

const TRUCK_FIELD = (viewerLikeSubAccount) => {
	return {
		key: "truckAccess",
		label: "Truck Access",
		type: "select",
		defaultValue: "FULL_TRUCK",
		field: { noErrorMessage: true },
		required: true,
		options: [
			{
				value: "FULL_TRUCK",
				label: "All Trucks",
			},
			{
				value: "PARTIAL_TRUCK",
				label: "Specifics Trucks",
				showItems: [
					{
						key: "trucks",
						type: "autocomplete",
						label: "Specific Truck",
						required: true,
						field: { noErrorMessage: true },
						autocomplete: {
							provider: "smarthop",
							url: !viewerLikeSubAccount ? "api/profile/trucks" : "api/profile/carriers/:carrierId/trucks",
							preloadDefault: true,
							preloadImmedately: true,
							multiple: true,
							params: {
								options_active_only: true,
								options_not_use_context: true,
							},
						},
						rules: !viewerLikeSubAccount
							? { params: { valueRef: "carrier", paramKey: "options_carrier_id_search" } }
							: undefined,
					},
				],
			},
		],
		rules: !viewerLikeSubAccount ? { disabled: { ifEmptyRef: "carrier" } } : undefined,
	};
};

const TRUCK_ACCESS_SECTION = ({ mode, viewerLikeSubAccount, roleType }) => {
	if (mode === "VIEW") {
		return {
			key: "accessType",
			type: "text",
			label: "Type",
			builder: (data) => {
				return getTruckAccessType(data.truckAccess);
			},
		};
	}

	return {
		key: "truckAccess",
		type: "object",
		content: {
			items: [
				{
					type: "group",
					content: {
						items: [
							...(!viewerLikeSubAccount
								? [
										{
											key: "accessType",
											label: "Access Type",
											type: "select",
											defaultValue: "FULL",
											field: { noErrorMessage: true },
											required: true,
											options: [
												{
													value: "FULL",
													label: "Allow Access All Truck From All Carriers",
												},
												{
													value: "PARTIAL",
													label: "Access to Specific Carriers an Trucks",
													showItems: [
														{
															key: "items",
															type: "pattern",
															pattern: {
																min: 1,
																disableMoveButton: true,
																disableInsertButtons: true,
																classes: { root: "mx-10" },
															},
															content: {
																items: [
																	{
																		key: "carrier",
																		type: "autocomplete",
																		label: "Carrier",
																		required: true,
																		field: { noErrorMessage: true },
																		autocomplete: {
																			provider: "smarthop",
																			url: "api/profile/carriers",
																			preloadDefault: true,
																			params: {
																				options_active_only: true,
																				...(roleType === "INTERNAL" ? { carrier_id: ":carrierId" } : {}),
																			},
																		},
																	},
																	...[TRUCK_FIELD(viewerLikeSubAccount)],
																],
															},
														},
													],
												},
											],
										},
								  ]
								: [TRUCK_FIELD(viewerLikeSubAccount)]),
						],
					},
				},
			],
		},
	};
};

const DRIVER_PROFILE_SECTION = ({ mode, driverProfileArchived, multiUser, fuelEnabled }) => ({
	key: "driverProfile",
	type: "object",
	content: {
		items: [
			{
				key: "deleted",
				type: "checkbox",
				label: "Active",
				disabled: mode === "CREATE",
				checkbox: {
					inverted: true,
					noErrorMessage: true,
					disclaimer: "Archived driver profile can not be selected in a truck and used for load search",
				},
			},
			...(driverProfileArchived || multiUser || !fuelEnabled
				? updateConfig(DRIVER_FIELDS, [
						...(driverProfileArchived ? [{ type: "DISABLED", value: true }] : []),
						...(multiUser ? [{ type: "REMOVE_FILED", value: "phone" }] : []),
						...(!fuelEnabled || multiUser ? [{ type: "REMOVE_FILED", value: "onrampAccountId" }] : []),
				  ])
				: DRIVER_FIELDS),
		],
	},
});

const DRIVER_PAY_SECTION = ({ mode, driverProfileArchived }) => ({
	key: "driverPay",
	type: "object",
	content: {
		items: [
			...(mode !== "VIEW"
				? [
						{
							key: "driver_pay_info",
							type: "banner",
							label: "Driver pay is required to correctly calculate profitability of a load",
							banner: { icon: "info", classes: { icon: "text-blue-600" } },
						},
				  ]
				: []),
			...(driverProfileArchived
				? updateConfig(DRIVER_PAY_FIELDS, { type: "DISABLED", value: true })
				: DRIVER_PAY_FIELDS),
		],
	},
});

const DRIVER_PAYROLL_SECTION = ({ mode, userPayrollArchived, fuelEnabled }) => ({
	key: "driverPayroll",
	type: "object",
	content: {
		items: [
			{
				type: "group",
				content: {
					items: [
						{
							key: "enroll_payroll",
							type: "checkbox",
							label: "Enrolled in Payroll",
							checkbox: { noErrorMessage: true },
						},
					],
				},
			},
			...(userPayrollArchived
				? updateConfig(DRIVER_PAYROLL_FIELDS({ mode, fuelEnabled }), { type: "DISABLED", value: true })
				: DRIVER_PAYROLL_FIELDS({ mode, fuelEnabled })),
		],
	},
});

const DISPATCHER_PAYROLL_SECTION = ({ mode, userPayrollArchived }) => ({
	key: "dispatcherPayroll",
	type: "object",
	content: {
		items: [
			{
				key: "enroll_payroll",
				type: "checkbox",
				label: "Enrolled in Payroll",
				checkbox: { noErrorMessage: true },
			},
			...(userPayrollArchived
				? updateConfig(DISPATCHER_PAYROLL_FIELDS({ mode }), { type: "DISABLED", value: true })
				: DISPATCHER_PAYROLL_FIELDS({ mode })),
		],
	},
});

const INVESTOR_PAYROLL_SECTION = ({ mode, userPayrollArchived, fuelEnabled }) => ({
	key: "investorPayroll",
	type: "object",
	content: {
		items: [
			{
				key: "enroll_payroll",
				type: "checkbox",
				label: "Enrolled in Payroll",
				checkbox: { noErrorMessage: true },
			},
			...(userPayrollArchived
				? updateConfig(INVESTOR_PAYROLL_FIELDS({ mode, fuelEnabled }), { type: "DISABLED", value: true })
				: INVESTOR_PAYROLL_FIELDS({ mode, fuelEnabled })),
		],
	},
});

const INVESTOR_FUEL_SECTION = ({ userPayrollArchived }) => ({
	key: "investorFuel",
	type: "object",
	content: {
		items: [
			...(userPayrollArchived
				? updateConfig(INVESTOR_PROFILE_FIELDS, { type: "DISABLED", value: true })
				: INVESTOR_PROFILE_FIELDS),
		],
	},
});

const createUserEditFrom = (
	{
		role,
		roleType,
		driverProfileAllowed,
		dispatcherProfileAllowed,
		accessDeactivated,
		driverProfileArchived,
		userPayrollArchived,
		userRoleSupportsPayroll,
		enabledPermissions,
		configsPermissions,
		enabledRoles,
		roleChanged,
		canBeDectivated,
		canBeEditEmail,
		multiUser,
		viewerCanEditRole,
		viewerLikeSubAccount,
		hasUsersAccessPermission,
	} = {},
	{ payrollEnabled, invoiceEnabled, fuelEnabled, mode, origin, initUserData, isLiteVersion } = {}
) => {
	const isEnabledSMS = isEnabled("SMS_CHAT_ENABLED");
	if (mode === "DELETE") {
		return {
			noInitValidation: true,
			items: [
				{
					key: "message",
					type: "message",
					label: "Would you like to continue and deactivate this user?",
					message: { classes: { root: " mt-10 pb-12 -mb-1" } },
				},
				{
					key: "deactivateAccess",
					type: "checkbox",
					label: "Disable Portal Access",
					disabled: accessDeactivated || !canBeDectivated,
					defaultValue: accessDeactivated ? true : origin === "DRIVER" ? false : !!canBeDectivated,
					checkbox: {
						noErrorMessage: true,
						disclaimer: "Inactive user won't be able to access mobile app or web portal",
					},
				},
				// No need to show option to deactivate driver profile if it's already deactivcated
				...(!driverProfileAllowed
					? []
					: [
							{
								key: "deactivateDriver",
								type: "checkbox",
								label: "Archive Driver Profile",
								disabled: driverProfileArchived,
								defaultValue: true,
								checkbox: {
									noErrorMessage: true,
									disclaimer: "Archived driver profile can not be selected in a truck and used for load search",
								},
							},
					  ]),
				// No need to show option to deactivate payroll if it's already deactivcated
				...(!payrollEnabled || !userRoleSupportsPayroll
					? []
					: [
							{
								key: "deactivatePayroll",
								type: "checkbox",
								label: "Unenroll from Payroll",
								disabled: userPayrollArchived,
								defaultValue: true,
								checkbox: { noErrorMessage: true, disclaimer: "Payroll module would ignore this user" },
							},
					  ]),
				{
					type: "group",
					group: { classes: { root: "mt-10" } },
					content: {
						items: [
							{ key: "cancel", type: "action", action: "CANCEL" },
							{ key: "action", type: "action", action: "DELETE", label: "Continue", button: { alwaysEnabled: true } },
						],
					},
				},
			],
		};
	} else if (mode === "VIEW") {
		return {
			summary: {
				items: [...USER_FIELDS(mode, canBeDectivated, canBeEditEmail)],
			},
			permissions: {
				items: [PERMISSION_SECTION({ enabledPermissions, configsPermissions, payrollEnabled, invoiceEnabled })],
			},
			webProfile: {
				items: [
					...WEB_PROFILE_SECTION({
						accessDeactivated,
						enabledPermissions,
						configsPermissions,
						payrollEnabled,
						invoiceEnabled,
						isEnabledSMS,
					}),
				],
			},
			truckAccess: dispatcherProfileAllowed
				? { items: [TRUCK_ACCESS_SECTION({ mode, viewerLikeSubAccount, roleType })] }
				: null,
			driverProfile: driverProfileAllowed
				? { items: [DRIVER_PROFILE_SECTION({ mode, driverProfileArchived, multiUser, fuelEnabled })] }
				: null,
			driverPay:
				driverProfileAllowed && !payrollEnabled
					? { items: [DRIVER_PAY_SECTION({ mode, driverProfileArchived })] }
					: null,
			investorFuel:
				role === ROLE_INVESTOR_KEY && fuelEnabled
					? { items: [INVESTOR_FUEL_SECTION({ userPayrollArchived, fuelEnabled })] }
					: null,
			payrollProfile: payrollEnabled
				? driverProfileAllowed
					? { items: [DRIVER_PAYROLL_SECTION({ mode, userPayrollArchived, fuelEnabled })] }
					: role === ROLE_DISPATCHER_KEY
					? { items: [DISPATCHER_PAYROLL_SECTION({ mode, userPayrollArchived })] }
					: role === ROLE_INVESTOR_KEY
					? { items: [INVESTOR_PAYROLL_SECTION({ mode, userPayrollArchived, fuelEnabled })] }
					: null
				: null,
		};
	} else if (mode === "COMPARE") {
		return {
			items: [
				{
					type: "section",
					label: "User Information",
				},
				{
					type: "group",
					content: {
						items: [
							{
								key: "first_name",
								type: "text",
								label: "First Name",
							},
							{
								key: "last_name",
								type: "text",
								label: "Last Name",
							},
						],
					},
				},
				{
					key: "roles",
					label: "roles",
					type: "message",
					builder: (data) => {
						const role = getUserRolesLabel(data.roles);
						return (
							<div className="flex">
								<Typography className="text-13 mr-6"> {"Roles"}</Typography>
								<Typography className="text-13 text-grey-700"> {role}</Typography>
							</div>
						);
					},
				},
				{
					label: "Portal Access",
					type: "section",
				},
				{
					key: "portal",
					label: "Portal Access",
					type: "message",
					builder: (data) => {
						const counters = getPermissionsCounters(data?.permissions);
						const text = counters?.viewCount + " View, " + counters?.editCount + " Edit";
						return (
							<div className="flex">
								<Typography className="text-13 mr-6"> {"Permissions"}</Typography>
								<Typography className="text-13 text-grey-700"> {text}</Typography>
							</div>
						);
					},
				},
				{
					key: "deactivated",
					type: "checkbox",
					label: "Allow Web Access",
					disabled: !canBeDectivated || !hasUsersAccessPermission,
					checkbox: { noErrorMessage: true, variant: "skinny", inverted: true },
				},
				...(isEnabledSMS
					? [
							{
								key: "sms_notify_opt_in",
								type: "checkbox",
								description: "Get notifications and chat messages VIA SMS if the user does not have the app installed.",
								label: "SMS Notifications",
								checkbox: { noErrorMessage: true, variant: "skinny" },
							},
					  ]
					: []),
				...(driverProfileAllowed
					? [
							{
								type: "section",
								label: "Driver profile",
							},
							...(driverProfileArchived || multiUser || !fuelEnabled
								? updateConfig(DRIVER_FIELDS, [
										...(driverProfileArchived ? [{ type: "DISABLED", value: true }] : []),
										...(multiUser ? [{ type: "REMOVE_FILED", value: "phone" }] : []),
										...(!fuelEnabled || multiUser ? [{ type: "REMOVE_FILED", value: "onrampAccountId" }] : []),
								  ])
								: DRIVER_FIELDS),
					  ]
					: []),
				...(payrollEnabled
					? [
							{
								type: "section",
								label: "Payroll",
							},
							{
								key: "enroll_payroll",
								type: "checkbox",
								label: "Enrolled in Payroll",
								checkbox: { noErrorMessage: true },
							},
							...DRIVER_PAYROLL_FIELDS({ mode, fuelEnabled }),
					  ]
					: [
							{
								type: "section",
								label: "Driver Pay",
							},
							...DRIVER_PAY_FIELDS,
					  ]),
			],
		};
	}
	if (isLiteVersion) {
		return {
			noInitValidation: true,
			items: [...USER_LITE_FIELDS(), { key: "action", type: "action" }],
		};
	}
	return {
		noInitValidation: true,
		items: [
			...USER_FIELDS(mode, canBeDectivated, canBeEditEmail),
			{
				key: "rolesToggle",
				type: "toggle",
				label: "Role",
				toggle: {
					icon: "build_circle",
					defaultVisible: origin !== "DRIVER" && (mode === "CREATE" || roleChanged),
				},
				summary: (data) => {
					const text = getUserRolesLabel(data.roles);
					return (
						<Typography className={"text-12 " + (text.length > 0 ? " text-blue-600 " : " text-grey ")}>
							{text.length > 0 ? text : "Not Selected"}
						</Typography>
					);
				},
				content: {
					items: [
						{
							key: "roles",
							type: "object",
							object: {
								schema: () => {
									return yup
										.object()
										.shape({
											[ROLE_OWNER_KEY]: yup.boolean(),
											[ROLE_MANAGER_KEY]: yup.boolean(),
											[ROLE_DISPATCHER_KEY]: yup.boolean(),
											[ROLE_INVESTOR_KEY]: yup.boolean(),
											[ROLE_DRIVER_KEY]: yup.boolean(),
										})
										.test("oneRoleRequires", "Please select at least one role", (value, context) => {
											return (
												value[ROLE_OWNER_KEY] ||
												value[ROLE_MANAGER_KEY] ||
												value[ROLE_DISPATCHER_KEY] ||
												value[ROLE_INVESTOR_KEY] ||
												value[ROLE_DRIVER_KEY]
											);
										});
								},
							},
							content: { items: [...ROLE_FIELDS({ viewerCanEditRole, enabledRoles, hasUsersAccessPermission })] },
						},
					],
				},
			},
			{
				key: "permissionsToggel",
				type: "toggle",
				label: "Portal Access",
				toggle: { icon: "phonelink", defaultVisible: origin === "PERMISSIONS" },
				summary: (data) => {
					const counters = getPermissionsCounters(data?.permissions);
					return data.deactivated ? (
						<Typography className="text-red text-12">
							<Icon className="text-14 -mb-2 mr-2">remove_circle</Icon>Access Disabled
						</Typography>
					) : (
						"Permissions: " + counters?.viewCount + " View, " + counters?.editCount + " Edit"
					);
				},
				content: {
					items: [
						{
							key: "deactivated",
							type: "checkbox",
							label: "Allow Web Access",
							disabled: !canBeDectivated || !hasUsersAccessPermission,
							checkbox: { noErrorMessage: true, variant: "skinny", inverted: true },
						},
						...(isEnabledSMS
							? [
									{
										key: "sms_notify_opt_in",
										type: "checkbox",
										description:
											"Get notifications and chat messages VIA SMS if the user does not have the app installed.",
										label: "SMS Notifications",
										disabled: !canBeDectivated || !hasUsersAccessPermission || initUserData?.user?.sms_notify_opt_out,
										checkbox: { noErrorMessage: true, variant: "skinny" },
									},
							  ]
							: []),
						PERMISSION_SECTION({ enabledPermissions, configsPermissions, payrollEnabled, invoiceEnabled }),
					],
				},
			},
			...(dispatcherProfileAllowed
				? [
						{
							key: "truckAccessToggel",
							type: "toggle",
							label: "Truck Access",
							toggle: { icon: "local_shipping", defaultVisible: origin === "PERMISSIONS" },
							summary: (data) => {
								return data.deactivated ? (
									<Typography className="text-red text-12">
										<Icon className="text-14 -mb-2 mr-2">remove_circle</Icon>Access Disabled
									</Typography>
								) : (
									<Typography className="text-12 text-blue-600">{getTruckAccessType(data.truckAccess)}</Typography>
								);
							},
							content: {
								items: [TRUCK_ACCESS_SECTION({ mode, viewerLikeSubAccount, roleType })],
							},
						},
				  ]
				: []),
			...(driverProfileAllowed
				? [
						{
							key: "driver_profile_toggle",
							type: "toggle",
							label: "Driver Profile",
							toggle: { icon: "directions_bus", defaultVisible: mode === "CREATE" || roleChanged },
							summary: (data) => {
								return (
									<Typography
										className={
											"text-12 " +
											(data.driverProfile?.deleted
												? " text-red-600 "
												: data.driverProfile?.driver_type
												? " text-blue-600 "
												: " text-grey ")
										}
									>
										{data.driverProfile?.deleted && <Icon className="text-14 -mb-2 mr-2">visibility_off</Icon>}
										{data.driverProfile?.deleted
											? "Archived"
											: data.driverProfile?.driver_type === "COMPANY_DRIVER"
											? "Company Driver"
											: data.driverProfile?.driver_type === "OWNER_OPERATOR"
											? "Owner Operator"
											: "No Type"}
									</Typography>
								);
							},
							content: {
								items: [DRIVER_PROFILE_SECTION({ mode, driverProfileArchived, multiUser, fuelEnabled })],
							},
						},
						...(payrollEnabled
							? [
									{
										key: "driver_payroll_toggle",
										type: "toggle",
										label: "Driver Payroll",
										toggle: { icon: "local_atm", defaultVisible: mode === "CREATE" || roleChanged },
										summary: (data) => {
											return (
												<Typography
													className={"text-12" + (data.driverPayroll?.enroll_payroll ? " text-green " : " text-red ")}
												>
													{!data.driverPayroll?.enroll_payroll && (
														<Icon className="text-14 -mb-2 mr-2">remove_circle</Icon>
													)}
													{data.driverPayroll?.enroll_payroll ? "Enrolled" : "Not Enrolled"}
												</Typography>
											);
										},
										...(userPayrollArchived ? { schema: () => null } : {}),
										content: { items: [DRIVER_PAYROLL_SECTION({ userPayrollArchived, fuelEnabled })] },
									},
							  ]
							: [
									{
										key: "driver_pay_toggle",
										type: "toggle",
										label: "Driver Pay",
										toggle: { icon: "monetization_on", defaultVisible: mode === "CREATE" || roleChanged },
										content: { items: [DRIVER_PAY_SECTION({ mode: driverProfileArchived })] },
									},
							  ]),
				  ]
				: []),
			...(role === ROLE_DISPATCHER_KEY && payrollEnabled
				? [
						{
							key: "dispatcher_payroll_toggle",
							type: "toggle",
							label: "Dispatcher Payroll",
							toggle: { icon: "local_atm", defaultVisible: mode === "CREATE" || roleChanged },
							summary: (data) => {
								return (
									<Typography
										className={"text-12" + (data.dispatcherPayroll?.enroll_payroll ? " text-green " : " text-red ")}
									>
										{!data.dispatcherPayroll?.enroll_payroll && (
											<Icon className="text-14 -mb-2 mr-2">remove_circle</Icon>
										)}
										{data.dispatcherPayroll?.enroll_payroll ? "Enrolled" : "Not Enrolled"}
									</Typography>
								);
							},
							...(userPayrollArchived ? { schema: () => null } : {}),
							content: { items: [DISPATCHER_PAYROLL_SECTION({ userPayrollArchived })] },
						},
				  ]
				: []),
			...(role === ROLE_INVESTOR_KEY
				? [
						...(fuelEnabled && mode === "EDIT"
							? [
									{
										key: "investor_profile_toggle",
										type: "toggle",
										label: "Investor Fuel",
										toggle: { icon: "local_gas_station", defaultVisible: mode === "EDIT" || roleChanged },
										content: {
											items: [INVESTOR_FUEL_SECTION({ userPayrollArchived })],
										},
									},
							  ]
							: []),
						...(payrollEnabled
							? [
									{
										key: "investor_payroll_toggle",
										type: "toggle",
										label: "Investor Payroll",
										toggle: { icon: "attach_money", defaultVisible: mode === "CREATE" || roleChanged },
										summary: (data) => {
											return (
												<Typography
													className={"text-12" + (data.investorPayroll?.enroll_payroll ? " text-green " : " text-red ")}
												>
													{!data.investorPayroll?.enroll_payroll && (
														<Icon className="text-14 -mb-2 mr-2">remove_circle</Icon>
													)}
													{data.investorPayroll?.enroll_payroll ? "Enrolled" : "Not Enrolled"}
												</Typography>
											);
										},
										...(userPayrollArchived ? { schema: () => null } : {}),
										content: { items: [INVESTOR_PAYROLL_SECTION({ userPayrollArchived, fuelEnabled })] },
									},
							  ]
							: []),
				  ]
				: []),
			{ key: "action", type: "action" },
		],
	};
};

export { createUserEditFrom };
