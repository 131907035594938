import { authRoles } from "app/main/consts";
import SetupPage from "./SetupPage";

const SetupPageConfig = {
	settings: {
		layout: {
			config: {
				navbar: {
					display: false,
				},
				toolbar: {
					display: false,
				},
				footer: {
					display: false,
				},
				leftSidePanel: {
					display: false,
				},
				rightSidePanel: {
					display: false,
				},
			},
		},
	},
	auth: authRoles.user,
	routes: [
		{
			path: "/setup",
			component: SetupPage,
		},
	],
};
export default SetupPageConfig;
