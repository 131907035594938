import { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { SmarthopConfirmDialog } from "@smarthop/form";
import { Typography } from "@material-ui/core";

// Utils
import { downloadFile, uploadFile } from "app/main/utils/fileUtils";
import { showSnackbar } from "app/main/utils/snackbarUtil";

// Tools
import PageSection from "../../common/PageSection";
import FormSection from "app/main/tools/FormSection";
import { incrementDataRevision } from "app/store/tools/revisionSlice";
import { openFormDialog, openLoadedFormDialog } from "app/store/tools/formDialogSlice";
import copy from "copy-to-clipboard";

// Services
import {
	getCsvOnboardedBrokers,
	uploadCsvBrokersLists,
	requestSendTestEmail,
	getCarrierBranding,
	updateCarrierBrandingMailConf,
	resetBrandingMailConfig,
	getCarrierFuelModule,
	updateCarrierFuelModule,
	resetInvoicingPaymentInformation,
	connectAxle,
	disconnectAxle,
} from "app/services/carrierServices";
import { getCarrierConfig } from "app/services/carrierServices";
import { syncFuelCarrier } from "app/services/fuelServices";
import { isEnabled } from "app/services/featureStorageService";
import { getUserTier } from "app/services/LoginService";
import { useHistory } from "react-router";

function CarriersCompanyView(props) {
	const dispatch = useDispatch();
	const snackbar = useSnackbar();

	const user = useSelector(({ auth }) => auth.user);
	const profileRevision = useSelector(({ tools }) => tools.revision["profileRevision"]);

	const userTier = getUserTier();
	const isInternal = user.roleType === "INTERNAL";
	const history = useHistory();

	const carrierId = props.carrierId;

	const [confirmDialog, setConfirmDialog] = useState({});
	const [branding, setBranding] = useState();
	const [fuelModule, setFuelModule] = useState();

	// Check that the carrier has the module activated and the Gk is open
	const [carrierConfig, setCarrierConfig] = useState();
	const isPayrollEnabled = carrierConfig?.enterprise_features?.payroll_module_flag;
	const isInvoicingEnabled = carrierConfig?.enterprise_features?.invoicingModuleOnboarding === "COMPLETE";

	const hasMailConfiguration = useMemo(() => {
		return (
			(branding?.email_smtp?.authentication?.type === "OAUTH" && branding?.email_smtp?.authentication?.access_token) ||
			(branding?.email_smtp?.authentication?.type === "PASSWORD" && branding?.email_smtp?.server_name)
		);
	}, [branding]);

	const downLoadList = (listType) => async () => {
		try {
			const data = await getCsvOnboardedBrokers(carrierId, listType);
			const fileName = listType.slice(0, 1).toUpperCase() + listType.slice(1) + " Brokers";
			downloadFile(data, fileName, "text/csv");
		} catch (error) {
			showSnackbar(snackbar, error.errors?.[0]?.message ?? "Opps, operation failed...", "error", { persist: true });
		}
	};

	const onClickUploadList = (listType) => () => {
		uploadFile("text/csv", (data) => {
			uploadList(data, listType);
		});
	};

	const uploadList = async (data, listType) => {
		try {
			await uploadCsvBrokersLists(carrierId, listType, data);
			dispatch(incrementDataRevision({ event: "profileRevision" }));
			dispatch(incrementDataRevision({ event: "onboardingRevision" }));
		} catch (error) {
			showSnackbar(snackbar, error.errors?.[0]?.message ?? "Opps, operation failed...", "error", { persist: true });
		}
	};

	const syncCarrierFuelModule = async () => {
		try {
			const response = await syncFuelCarrier(carrierId);
			dispatch(incrementDataRevision({ event: "profileRevision" }));
			showSnackbar(snackbar, response.message ?? `Synchronization executed`, "success");
		} catch (error) {
			showSnackbar(snackbar, error.errors?.[0]?.message ?? "Opps, operation failed...", "error", { persist: true });
		}
	};

	const sendBrandingMailConfigTestEmail = async (carrierId) => {
		try {
			const emailAddress = branding?.email_smtp.authentication.username;
			setConfirmDialog({
				open: true,
				message: `We'll send a test email to ${emailAddress}`,
				handleAccept: async () => {
					setConfirmDialog({ open: false });
					await requestSendTestEmail(carrierId);
					showSnackbar(snackbar, `Test Email sent succesfully`, "success");
				},
				handleClose: () => setConfirmDialog({ open: false }),
				acceptMsg: "Send",
			});
		} catch (error) {
			showSnackbar(snackbar, error.errors?.[0]?.message ?? "Could not send a Test Email.", "error", { persist: true });
		}
	};

	const resetBrandingMail = async (carrierId) => {
		try {
			setConfirmDialog({
				open: true,
				message: `You will reset your SMTP configuration`,
				handleAccept: async () => {
					setConfirmDialog({ open: false });
					await resetBrandingMailConfig(carrierId);
					dispatch(incrementDataRevision({ event: "profileRevision" }));
					showSnackbar(snackbar, `Reset SMTP configuration succesfully`, "success");
				},
				handleClose: () => setConfirmDialog({ open: false }),
				acceptMsg: "Reset",
			});
		} catch (error) {
			showSnackbar(snackbar, error.errors?.[0]?.message ?? "Could not reset SMTP configuration", "error", {
				persist: true,
			});
		}
	};

	const switchSMTPEmailState = async (carrierId) => {
		try {
			const enabled = branding?.email_smtp.enabled;
			await updateCarrierBrandingMailConf(carrierId, {
				enabled: !enabled,
			});
			dispatch(incrementDataRevision({ event: "profileRevision" }));
			showSnackbar(snackbar, enabled ? "SMTP Disabled succesfully" : "SMTP Enabled succesfully", "success");
		} catch (error) {
			showSnackbar(snackbar, error.errors?.[0]?.message ?? "Could not save configuration.", "error", { persist: true });
		}
	};

	const switchFuelModuleState = async () => {
		try {
			const ENABLED = "ENABLED";
			const DISABLED = "DISABLED";
			const onboardingFuelModule = fuelModule?.onboardingFuelModule === ENABLED ? DISABLED : ENABLED;
			await updateCarrierFuelModule(carrierId, { onboardingFuelModule });
			dispatch(incrementDataRevision({ event: "profileRevision" }));
			showSnackbar(snackbar, `Fuel Module ${onboardingFuelModule.toLowerCase()} succesfully`, "success");
		} catch (error) {
			showSnackbar(snackbar, error.errors?.[0]?.message ?? "Could not save configuration.", "error", { persist: true });
		}
	};

	const resetExternalFactoring = async (carrierId) => {
		try {
			setConfirmDialog({
				open: true,
				message: `You will clear your Invoicing Payment Information`,
				handleAccept: async () => {
					setConfirmDialog({ open: false });
					await resetInvoicingPaymentInformation(carrierId);
					dispatch(incrementDataRevision({ event: "profileRevision" }));
					showSnackbar(snackbar, `Invoicing Payment Information cleared succesfully`, "success");
				},
				handleClose: () => setConfirmDialog({ open: false }),
				acceptMsg: "Clear",
			});
		} catch (error) {
			showSnackbar(snackbar, error.errors?.[0]?.message ?? "Could not clear Invoicing Payment Information", "error", {
				persist: true,
			});
		}
	};

	const onClickDisconnectAxle = async () => {
		await disconnectAxle(carrierId).then((data) => {
			dispatch(incrementDataRevision({ event: "profileRevision" }));
		});
	};

	const onClickConnectAxle = async () => {
		return connectAxle(carrierId).then((data) => {
			window.open(data.url, "_blank");
		});
	};

	const onClickShareLink = async () => {
		return connectAxle(carrierId).then((data) => {
			copy(data.url);
			showSnackbar(snackbar, `Link copied to clipboard`, "success");
		});
	};

	const onClikEditBrokerConfiguration = () => {
		dispatch(
			openFormDialog({
				viewId: "PAYWALL_VIEW",
				dataIds: {
					type: "default",
					data: { dataIdsInherited: { carrierId }, callbackKey: "CARRIER_NOTIFICATION_BROKER_CONFIGURATION_FORM" },
				},
			})
		);
	};

	// Alternate way to find out if the payroll module is active for the carrier
	useEffect(() => {
		(async () => {
			if (carrierId) {
				const [config, carrierBranding, carrierFuelModule] = await Promise.all([
					getCarrierConfig(carrierId),
					getCarrierBranding(carrierId),
					getCarrierFuelModule(carrierId),
				]);
				setCarrierConfig(config ?? {});
				setBranding(carrierBranding ?? {});
				setFuelModule(carrierFuelModule ?? {});
			}
		})();
	}, [carrierId, profileRevision]);

	return (
		<>
			<div className="flex flex-col md:flex-row items-start w-full space-x-0 md:space-x-10">
				<FormSection
					title="Information"
					formId="CARRIER_COMPANY_EDIT_AND_VALIDATE_FORM"
					dataIds={{ carrierId: carrierId }}
					actions={[
						{
							action: "edit",
							mode: "EDIT",
							label: "Edit",
							auth: ["administrator", "carrier", "carrier_manager"],
						},
						{
							action: {
								onClick: () => {
									dispatch(
										openLoadedFormDialog({
											formId: "CARRIER_COMPANY_EDIT_FLEET_NAME_FORM",
											mode: "EDIT",
											dataIds: { carrierId },
										})
									);
								},
							},
							label: "Edit fleet name",
						},
						{
							action: {
								onClick: () => {
									dispatch(
										openFormDialog({ viewId: "COMPANY_INFO_CONFIRM", dataIds: { state: "VALIDATE", carrierId } })
									);
								},
							},
							label: "Validate",
							auth: ["administrator"],
						},
					]}
				/>
				<FormSection
					title="Documents"
					formId="CARRIER_DOCUMENTS_FORM"
					dataIds={{ carrierId: carrierId }}
					actions={[
						{
							action: "edit",
							mode: "EDIT",
							label: "Edit",
							auth: ["administrator", "carrier", "carrier_manager"],
						},
					]}
				/>
			</div>
			<div className="flex flex-col md:flex-row items-start w-full space-x-0 md:space-x-20">
				<FormSection
					title="Branding"
					formId="CARRIER_BRANDING_FORM"
					dataIds={{ carrierId: carrierId }}
					actions={[
						{
							action: "edit",
							mode: "EDIT",
							label: "Edit",
							auth: ["administrator", "carrier", "carrier_manager"],
						},
					]}
				/>
			</div>
			<div className="flex flex-col md:flex-row items-start w-full space-x-0 md:space-x-20">
				<FormSection
					title="Brokers"
					formId="CARRIER_BROKERS_FORM"
					dataIds={{ carrierId: carrierId }}
					actions={[
						{
							action: "edit",
							mode: "EDIT",
							label: "Edit",
							auth: ["administrator", "dispatcher", "carrier", "carrier_manager"],
						},
						{
							action: { onClick: downLoadList("onboarded") },
							label: "Download Onboarded",
							auth: ["administrator"],
						},
						{
							action: { onClick: onClickUploadList("onboarded") },
							label: "Upload Onboarded",
							auth: ["administrator"],
						},
						{
							action: { onClick: downLoadList("blacklisted") },
							label: "Download Blacklisted",
							auth: ["administrator"],
						},
						{
							action: { onClick: onClickUploadList("blacklisted") },
							label: "Upload Blacklisted",
							auth: ["administrator"],
						},
					]}
				/>
			</div>
			<PageSection variant="full" title="Communication" classes={{ root: " min-h-auto " }}>
				<FormSection
					title="Mail Configuration (SMTP)"
					formId={
						branding?.email_smtp?.authentication?.type === "OAUTH"
							? "CARRIER_BRANDING_MAIL_CONF_GMAIL_FORM"
							: hasMailConfiguration
							? "CARRIER_BRANDING_MAIL_CONF_FORM"
							: "CARRIER_BRANDING_EMPTY_MAIL_CONF_FORM"
					}
					dataIds={{ carrierId: carrierId }}
					actions={[
						{
							action: {
								onClick: () => {
									if (isEnabled("GMAIL_OAUTH") || isEnabled("OFFICE_OAUTH")) {
										dispatch(
											openFormDialog({
												viewId: "CARRIER_EMAIL_AUTH_TYPE",
												dataIds: { carrierId, mode: "EDIT", id: carrierId },
											})
										);
									} else {
										dispatch(
											openLoadedFormDialog({
												formId: "CARRIER_BRANDING_MAIL_CONF_FORM",
												dataIds: { carrierId, mode: "EDIT", id: carrierId },
												mode: "EDIT",
											})
										);
									}
								},
							},
							mode: "EDIT",
							label: "Edit",
							auth: ["administrator", "carrier", "carrier_manager"],
						},
						// Testing conf
						...(branding?.email_smtp?.server_name || branding?.email_smtp?.authentication?.access_token
							? [
									// Enable / Disable
									{
										action: { onClick: () => switchSMTPEmailState(carrierId) },
										label: branding?.email_smtp?.enabled ? "Disable" : "Enable",
										auth: ["administrator", "carrier", "carrier_manager"],
									},
									{
										action: { onClick: () => sendBrandingMailConfigTestEmail(carrierId) },
										label: "Test",
										auth: ["administrator", "carrier", "carrier_manager"],
									},
									{
										action: { onClick: () => resetBrandingMail(carrierId) },
										label: "Clear",
										auth: ["administrator", "carrier", "carrier_manager"],
									},
							  ]
							: []),
					]}
				/>
			</PageSection>

			{(userTier !== "TIER_STARTER" || isInternal) && (
				<>
					<PageSection variant="full" title="Automations" classes={{ root: " min-h-auto " }}>
						<FormSection
							title="Automation Configuration"
							formId={"CARRIER_AUTOMATION_FORM"}
							dataIds={{ carrierId: carrierId }}
							actions={[
								{
									action: "edit",
									mode: "EDIT",
									label: "Edit",
									auth: ["administrator", "carrier", "carrier_manager"],
								},
							]}
						/>
					</PageSection>
					<PageSection variant="full" title="Tracking" classes={{ root: " min-h-auto " }}>
						<FormSection
							title="Axle"
							formId="CARRIER_AXLE_CONNECTION"
							dataIds={{ carrierId: carrierId, enabled: carrierConfig?.axleConnection?.fleet_id }}
							actions={[
								...(carrierConfig?.axleConnection?.fleet_id
									? [
											{
												action: { onClick: () => onClickDisconnectAxle() },
												label: "Disconnect",
												auth: ["administrator", "carrier", "carrier_manager"],
											},
											// {
											// 	action: { onClick: () => history.push("/profile/axle/import") },
											// 	label: "Import Assets from “ELD",
											// 	auth: ["administrator", "carrier", "carrier_manager"],
											// },
									  ]
									: [
											{
												action: {
													onClick: () => onClickConnectAxle(),
												},
												label: "Connect",
												auth: ["administrator", "carrier", "carrier_manager"],
											},
											{
												action: {
													onClick: () => onClickShareLink(),
												},
												label: "Share Link",
												auth: ["administrator", "carrier", "carrier_manager"],
											},
									  ]),
							]}
						/>
					</PageSection>
					<div id={"invoice-settings"} />
					{isInvoicingEnabled && (
						<PageSection variant="full" title="Invoicing Module" classes={{ root: " min-h-auto " }}>
							<div className="flex flex-col md:flex-row items-start w-full space-x-0 md:space-x-10">
								<FormSection
									title="Settings"
									formId="CARRIER_INVOICING_CONF_FORM"
									dataIds={{ carrierId: carrierId }}
									actions={[
										{
											action: "edit",
											mode: "EDIT",
											label: "Edit",
											auth: ["administrator", "dispatcher", "carrier", "carrier_manager"],
										},
									]}
								/>
								{!carrierConfig?.hasSmartPayProgram && (
									<FormSection
										title="Payment Information"
										formId="CARRIER_EXTERNAL_FACTORING_CONFIG"
										dataIds={{ carrierId: carrierId }}
										actions={[
											...(!carrierConfig?.external_factoring
												? [
														{
															action: "create",
															mode: "CREATE",
															label: "Add",
															auth: ["administrator", "dispatcher", "carrier", "carrier_manager"],
														},
												  ]
												: [
														{
															action: "edit",
															mode: "EDIT",
															label: "Edit",
															auth: ["administrator", "dispatcher", "carrier", "carrier_manager"],
														},
														{
															action: { onClick: () => resetExternalFactoring(carrierId) },
															label: "Clear",
															auth: ["administrator", "carrier", "carrier_manager"],
														},
												  ]),
										]}
									/>
								)}
							</div>
							<div className="flex flex-col md:flex-row items-start w-full space-x-0 md:space-x-20"></div>
							<div className="flex flex-col md:flex-row items-start w-full space-x-0 md:space-x-20">
								<FormSection
									title="Internal Copy"
									formId="CARRIER_INVOICING_INTERNAL_COPY_CONF_FORM"
									dataIds={{ carrierId: carrierId }}
									actions={[
										{
											action: "edit",
											mode: "EDIT",
											label: "Edit",
											auth: ["administrator", "dispatcher", "carrier", "carrier_manager"],
										},
									]}
								/>
							</div>
						</PageSection>
					)}

					<div id={"payroll-settings"} />
					{isPayrollEnabled && (
						<PageSection variant="full" title="Payroll Module" classes={{ root: " min-h-auto " }}>
							<>
								<div className="flex flex-col md:flex-row items-start w-full space-x-0 md:space-x-10">
									<FormSection
										title="Settings"
										formId="CARRIER_PAYROLL_SETTINGS_FORM"
										dataIds={{ carrierId: carrierId }}
										actions={[
											{
												action: "edit",
												mode: "EDIT",
												label: "Edit",
												auth: ["administrator", "carrier", "carrier_manager"],
											},
										]}
									/>
									<div className="flex flex-col md:flex-row items-start w-full space-x-0 md:space-x-10">
										<FormSection
											title="Reference Number"
											formId="CARRIER_PAYROLL_REFERENCE_CONFIGURATION_FORM"
											dataIds={{ carrierId }}
										/>
									</div>
								</div>
								<div className="flex flex-col md:flex-row items-start w-full space-x-0 md:space-x-10">
									<FormSection
										title="Default Owner Operator Settings"
										formId="CARRIER_PAYROLL_OWNER_OPERATOR_CONFIGURATION_FORM"
										dataIds={{ carrierId: carrierId }}
										actions={[
											{
												action: "edit",
												mode: "EDIT",
												label: "Edit",
												auth: ["administrator", "carrier", "carrier_manager"],
											},
										]}
									/>
									<FormSection
										title="Default Company Driver Settings"
										formId="CARRIER_PAYROLL_DRIVER_CONFIGURATION_FORM"
										dataIds={{ carrierId: carrierId }}
										actions={[
											{
												action: "edit",
												mode: "EDIT",
												label: "Edit",
												auth: ["administrator", "carrier", "carrier_manager"],
											},
										]}
									/>
								</div>
							</>
						</PageSection>
					)}

					<PageSection variant="full" title="Notifications" classes={{ root: " min-h-auto " }}>
						<div className="flex flex-col md:flex-row items-start w-full space-x-0 md:space-x-10">
							<FormSection
								title="Performance Configuration"
								formId="CARRIER_NOTIFICATION_PERFORMANCE_CONFIGURATION_FORM"
								dataIds={{ carrierId: carrierId }}
								actions={[
									{
										action: "edit",
										mode: "EDIT",
										label: "Edit",
										auth: ["administrator", "carrier", "carrier_manager"],
									},
								]}
							/>
							<FormSection
								title="Broker Configuration"
								formId="CARRIER_NOTIFICATION_BROKER_CONFIGURATION_FORM"
								dataIds={{ carrierId: carrierId }}
								actions={
									userTier === "TIER_BASIC_PLUS"
										? [
												{
													action: { onClick: () => onClikEditBrokerConfiguration() },
													label: "Edit",
													auth: ["administrator", "carrier", "carrier_manager"],
												},
										  ]
										: [
												{
													action: "edit",
													mode: "EDIT",
													label: "Edit",
													auth: ["administrator", "carrier", "carrier_manager"],
												},
										  ]
								}
							/>
						</div>
					</PageSection>
				</>
			)}

			<FormSection
				title="Onboarding"
				formId="CARRIER_ONBOARDING_STATUS_FORM"
				dataIds={{ carrierId }}
				actions={[{ action: "edit", mode: "EDIT", label: "Edit", auth: ["administrator"] }]}
				auth={["administrator"]}
			/>
			<FormSection
				title="Configuration"
				formId="CARRIER_CONFIGURATION_FORM"
				dataIds={{ carrierId: carrierId }}
				actions={[{ action: "edit", mode: "EDIT", label: "Edit", auth: ["administrator"] }]}
				auth={["administrator", "dispatcher"]}
			/>
			<FormSection
				title="Fuel Module"
				formId="CUSTOMER_SYNC_FORM"
				dataIds={{ carrierId }}
				actions={[
					{
						action: { onClick: () => syncCarrierFuelModule() },
						label: "Sync Onramp Customer",
						auth: ["administrator"],
					},
					{
						action: { onClick: () => switchFuelModuleState(carrierId) },
						label: fuelModule?.onboardingFuelModule === "ENABLED" ? "Disable" : "Enable",
						auth: ["administrator"],
					},
				]}
				auth={["administrator", "ops support", "dispatcher"]}
			/>
			<FormSection
				title="SmartPay"
				formId="CARRIER_SMARTPAY_FORM"
				dataIds={{ carrierId }}
				actions={[{ action: "edit", mode: "EDIT", label: "Edit", auth: ["administrator"] }]}
				auth={["administrator"]}
			/>
			<FormSection
				title="Invoice Template"
				formId="CARRIER_INVOICE_TEMPLATE_FORM"
				dataIds={{ carrierId: carrierId }}
				actions={[{ action: "edit", mode: "EDIT", label: "Edit", auth: ["administrator", "ops support"] }]}
				auth={["administrator", "ops support", "dispatcher"]}
			/>

			<SmarthopConfirmDialog
				open={confirmDialog?.open ?? false}
				title={confirmDialog?.title}
				handleClose={confirmDialog?.handleClose}
				handleAccept={confirmDialog?.handleAccept}
				closeMsg={confirmDialog?.closeMsg}
				acceptMsg={confirmDialog?.acceptMsg}
			>
				<Typography className="text-16">{confirmDialog?.message}</Typography>
			</SmarthopConfirmDialog>
		</>
	);
}

export default CarriersCompanyView;
