import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import FuseSplashScreen from "@fuse/core/FuseSplashScreen";
import { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "@reduxjs/toolkit";
import { motion } from "framer-motion";

import { setUserData, logoutUser, forceLogoutUser } from "app/store/auth/userSlice";
import { getAuthToken, signInWithToken } from "app/services/LoginService";
import ErrorGenericPage from "app/main/errors/generic/ErrorGenericPage";
import * as featureService from "app/services/featureService";

class Auth extends Component {
	state = {
		componentId: "Auth",
		errorStatus: null,
		waitAuthCheck: true,
		needToReload: false,
	};

	componentDidMount() {
		this.reAuth();
	}

	reAuth() {
		const disableAuth = window?.location?.href?.includes("/native/");
		if (disableAuth) {
			console.log("[ConnectionHandler] disableAuth", disableAuth);
			return;
		}

		if (!getAuthToken()) {
			return featureService.getDefaultGatekeepers().then((features) => {
				featureService.set(features);
				this.setState({ waitAuthCheck: false, needToReload: false });
			});
		}

		return signInWithToken()
			.then((data) => {
				this.props.setUserData(data);
				this.setState({ waitAuthCheck: false });
				this.setState({ needToReload: false });
			})
			.catch((error) => {
				this.setState({ needToReload: true, errorStatus: error?.status, waitAuthCheck: false });
			});
	}

	reAuthDelayed() {
		this.setState({ waitAuthCheck: true, needToReload: false });
		setTimeout(
			function () {
				this.reAuth();
			}.bind(this),
			500
		);
	}

	forceLogout() {
		this.setState({ waitAuthCheck: false, needToReload: false });
		this.props.forceLogout();
	}

	render() {
		// Disable auth for all native mobile pages
		const disableAuth = window?.location?.href?.includes("/native/");
		if (disableAuth) {
			return this.props.children;
		}

		return this.state.waitAuthCheck && !disableAuth ? (
			<FuseSplashScreen />
		) : this.state.needToReload && !disableAuth ? (
			<div id="fuse-splash-screen-error">
				<div className="center">
					<ErrorGenericPage status={this.state.errorStatus} />

					{this.state.errorStatus === 401 || this.state.errorStatus === 403 ? (
						<motion.div initial={{ opacity: 0, y: 40 }} animate={{ opacity: 1, y: 0, transition: { delay: 0.6 } }}>
							<div className="flex flex-1 items-center justify-center">
								<Button
									className="font-bold text-13 py-6 px-48 font-sans tracking-wide rounded-3xl"
									variant="outlined"
									style={{ color: "#E7683D", borderColor: "#E7683D" }}
									onClick={() => this.forceLogout()}
								>
									Login Again
								</Button>
							</div>
						</motion.div>
					) : (
						<motion.div initial={{ opacity: 0, y: 40 }} animate={{ opacity: 1, y: 0, transition: { delay: 0.6 } }}>
							<div className="flex flex-col ml:flex-row lex-1 items-center justify-center">
								<Button
									className="font-bold text-13 py-6 px-48 font-sans tracking-wide rounded-3xl"
									variant="outlined"
									style={{ color: "#062246", borderColor: "#062246" }}
									onClick={() => this.reAuthDelayed()}
								>
									Reload Page
								</Button>
								<Typography color="textSecondary" className="text-13 font-bold px-24 py-10">
									OR
								</Typography>
								<Button
									className="font-bold text-13 py-6 px-36 font-sans tracking-wide rounded-3xl"
									variant="outlined"
									style={{ color: "#E7683D", borderColor: "#E7683D" }}
									onClick={() => this.forceLogout()}
								>
									Try Login Again
								</Button>
							</div>
						</motion.div>
					)}
				</div>
			</div>
		) : (
			<>{this.props.children}</>
		);
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			logout: logoutUser,
			forceLogout: forceLogoutUser,
			setUserData,
		},
		dispatch
	);
}

export default connect(null, mapDispatchToProps)(Auth);
