import { Typography } from "@material-ui/core";

function SetupSidebar({ config, data }) {
	return (
		<div className="md:mb-16 md:mt-16 flex flex-col items-start justify-center ml-16">
			<img className="logo-icon w-200 " src="assets/images/logoSmartHopSECOND.png" alt="logo" />
			<Typography className="text-white text-20 md:text-28">Set up your Account</Typography>
		</div>
	);
}

export default SetupSidebar;
