import { Typography } from "@material-ui/core";

const RegisterTermAcknowledgement = () => {
	return (
		<div className="w-full md:max-w-md md:mx-auto">
			<div className="space-y-6">
				<Typography className="text-15 md:text-16 text-gray-600 leading-relaxed pb-16">
					Before paying for SmartHop, we want to make sure you understand that there are a few steps you&apos;ll have to
					take before using the platform.
				</Typography>

				<ol className="list-decimal list-inside text-gray-700 pb-16 text-15 md:text-16">
					<li className="pl-2 text-15 md:text-16 ">Provide a valid MC or DOT number</li>
					<li className="pl-2 text-15 md:text-16 ">Create at least one driver and one truck</li>
					<li className="pl-2 text-15 md:text-16 ">Attend a 15-minute onboarding call with our amazing team</li>
				</ol>

				<div className="mt-8 pt-12 border-t border-gray-200 pb-16">
					<Typography className="text-15 md:text-16 text-gray-600 leading-relaxed">
						By moving to the next step, you acknowledge that you understand and agree to the above requirements, and
						that you understand that we do not allow refunds.
					</Typography>
				</div>
			</div>
		</div>
	);
};

export default RegisterTermAcknowledgement;
