import { useDispatch } from "react-redux";
import Icon from "@material-ui/core/Icon";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import { Typography } from "@material-ui/core";

import { openFormDialog } from "app/store/tools/formDialogSlice";
import { isOnboardingNotSubmitted } from "app/services/LoginService";

function MarketMenuItem({ type, color }) {
	const dispatch = useDispatch();

	if (isOnboardingNotSubmitted()) {
		return null;
	}

	const handleClick = () => {
		dispatch(openFormDialog({ viewId: "MARKET_VIEW" }));
	};

	return (
		<>
			{type === "icon" ? (
				<Button className="mx-8 rounded-none" onClick={handleClick}>
					<div className="flex flex-col justify-center items-center">
						<Icon style={{ color }} size="small">
							map
						</Icon>
						<Typography style={{ color }} className="text-10">
							MARKET
						</Typography>
					</div>
				</Button>
			) : (
				<MenuItem onClick={handleClick} role="button">
					<ListItemIcon className="min-w-40">
						<Icon>map</Icon>
					</ListItemIcon>
					<ListItemText primary="Support" />
				</MenuItem>
			)}
		</>
	);
}

export default MarketMenuItem;
