import TruckView from "../../profile/trucks/TruckView";
import { Icon, Typography } from "@mui/material";

function CreateTruck({ data, switchToStep }) {
	return (
		<div className="w-full">
			<TruckView
				key={"setup_truck"}
				dataIds={{ carrierId: data.setup_onboarding_carrier_id, mode: "CREATE", version: "LITE", switchToStep }}
			/>
		</div>
	);
}

export default CreateTruck;
